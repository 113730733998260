import React from "react"
import "./Settings.css"
import Title from "../Title/Index.jsx";
import bcrypt from "bcryptjs";
import Axios from "axios";
import {useGlobalState} from "../../App";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import {getOldHash,updateNewPassword} from "../../services/api";

const ChangePassword = () => {


    const [newPassword, setNewPassword] = React.useState("")
    const [oldPassword, setOldPassword] = React.useState("")
    const [oldHashPass, setOldHashPass] = React.useState("")
    const [msg, setMsg] = React.useState("")
    const [err, setErr] = React.useState("")
    let user = useGlobalState('username')[0]

    React.useEffect(() => {
        getOldHash(user).then((response) => {
            if (response.data.err) console.log("Err")
            else setOldHashPass(response.data.password)
        })
    }, [])

    const updatePassword = () => {

        bcrypt.compare(oldPassword, oldHashPass, function (err, res) {
            if (res) {
                setErr("")
                setMsg("")
                if (newPassword === oldPassword)
                    setErr("Use a different password")
                else {
                    if (newPassword.match(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/))
                        bcrypt.genSalt(10, function (err, salt) {
                            bcrypt.hash(newPassword, salt, function (err, hash) {
                                updateNewPassword(user, hash).then((response) => {
                                    if (response.data.err) console.log("Err")
                                    else {
                                        setNewPassword("")
                                        setOldPassword("")
                                        setOldHashPass("")
                                        setMsg("New password saved correctly")
                                    }
                                })
                            });
                        })
                    else setErr("Password must contains at least one Uppercase letter, one number and be at least 8 character")
                }
            } else
                setErr("Passwords doesn't match")
        })

    }


    return (
        <div className="change-pass-tab">
            <Title Title1="Change your" Title2="Password"/>
            <div className="row-settings" style={{justifyContent: "space-between"}}>
                <div className="row-settings" style={{columnGap: 1 + "rem"}}>
                    <div className="pass-group">
                        <label className="text-black-16px">Insert old password:</label>
                        <input
                            type="password"
                            className="input-data text-black-16px"
                            id="oldPassInput"
                            name="oldPassInput"
                            placeholder="Enter old password"
                            onChange={(event) => setOldPassword(event.target.value)}
                        />

                    </div>
                    <div className="pass-group">
                        <label className="text-black-16px">Insert new password:</label>
                        <input
                            className="input-data text-black-16px"
                            id="newPassInput"
                            name="newPassInput"
                            placeholder="Enter new password"
                            onChange={(event) => setNewPassword(event.target.value)}
                        />

                    </div>
                </div>
                <button className="update-button text-white-16px" onClick={() => updatePassword()}>Save
                </button>
            </div>
            <small className="text-danger" style={{overflowWrap: "break-word"}}>{err}</small>
            <small className="text-black-14px" style={{overflowWrap: "break-word"}}>{msg}</small>
        </div>
    )
}

export default ChangePassword