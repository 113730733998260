/* eslint-disable prettier/prettier */
import * as React from "react";
import "../PlatformTrader/PlatformTrader.css";
import { Box } from "@chakra-ui/react";
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import Subscriptions from "../Subscriptions/index.tsx";

const SubscriptionsDashboard = () => (
    <div className="background">
        <Box
            className="w-full"
            bg="gray.200"
        >
            <Subscriptions />
        </Box>
    </div>
);

export default SubscriptionsDashboard;
