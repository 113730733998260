import React from "react"
import "./YourPortfolio.css"

const StrategyComposition = (props) => {

    const handleChange = (event) => {
        props.setWeight(
            props.newWeight.map((size) => {
                if (size['strategy'] === props.name) return {...size, weight: event.target.value}
                else return size
            })
        )
    }

    const handleLeverage  = (event) => {
        props.setWeight(
            props.newWeight.map((size) => {
                if (size['strategy'] === props.name) return {...size, leverage: event.target.value}
                else return size
            })
        )

    }

    const handleDelete = () => {
        props.setWeight(
            props.newWeight.map((size) => {
                if (size['strategy'] === props.name) return {...size, disabled: true}
                else return size
            })
        )
    }

    const handleAdd = () => {
        props.setWeight(
            props.newWeight.map((size) => {
                if (size['strategy'] === props.name) return {...size, disabled: false}
                else return size
            })
        )
    }

    return (
        <div className="strategy-composition" style={props.disabled ? {opacity: 0.3} : {opacity: 1}}>
            <div className="input-portfolio">
                <input
                    type="checkbox"
                    id={"selectedStrategy" + props.index}
                    onChange={(event) => {
                        props.disabled ? handleAdd() : handleDelete()
                    }}
                    defaultChecked={!props.disabled}
                />
                <label htmlFor={"selectedStrategy" + props.index}
                       className="text-black-16px"
                       style={{overflowWrap: 'anywhere'}}>{props.name.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</label>
            </div>
            <div style={{display: 'flex', gap: '0.5rem',flexWrap:'wrap'}}>
                <div className="input-portfolio">
                    <label className="text-black-16px">Leverage:</label>
                    <input
                        className="input-data-portfolio text-black-16px"
                        type="number"
                        id="leverage"
                        name="leverage"
                        defaultValue={props.leverage}
                        style={{width: '5em'}}
                        disabled={props.disabled}
                        onChange={(event) => {
                            handleLeverage(event)
                        }}
                    />
                </div>
                <div className="input-portfolio">
                    <label className="text-black-16px">Sizing:</label>
                    <input
                        className="input-data-portfolio text-black-16px"
                        type="number"
                        id="sizing"
                        name="sizing"
                        style={{width: '5em'}}
                        defaultValue={props.sizing}
                        disabled={props.disabled}
                        onChange={(event) => {
                            handleChange(event)
                        }}
                    />
                </div>
            </div>

        </div>

    )
}

export default StrategyComposition;