import React from 'react'
import Title from "../Title/Index.jsx";
import "./EditActivePortfolio.css"
import Modal from "../Modal/Index.jsx";
import ModalQueue from "../ModalQueue/Index.jsx";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import {useGlobalState} from "../../App";
import {sendSqs} from "../../services/api";


const EditActivePortfolio = (props) => {

    const [err, setErr] = React.useState(" ")
    const [msg, setMsg] = React.useState(" ")
    const [isPop, setIsPop] = React.useState(false)
    const [isShow, setIsShow] = React.useState(false)
    const [cutsPortfolios, setCutsPortfolios] = React.useState([]);
    const user = useGlobalState('username')[0]
    const id = useGlobalState('IdUser')[0]



    React.useEffect(() => {
        setCutsPortfolios(prev => [
            ...prev,
            ...props.exchangePortfolios.map(port => parseFloat(port.portfolio_cut) * 100)
        ]);
    }, []);
    const handleChange = (value, index) => {
        setCutsPortfolios(prev => {
            const newArray = [...prev];
            newArray[index] = parseFloat(value);
            return newArray;
        })
    }

    const sum = (array) => {
        return array.reduce((sum, current) => {
            return sum + current
        })
    }
    const validate = () => {
        setErr('')
        if (cutsPortfolios.every(element => props.exchangePortfolios.some(item => (parseFloat(item.portfolio_cut) * 100) === element))) {
            setErr('Insert new values')
        } else if (cutsPortfolios.every(el => el < 100 && el > 1)) {
            if (sum(cutsPortfolios) > 100 || sum(cutsPortfolios) < 1) {
                setErr('The total sum of the cuts must be between 1% and 100%')
            } else {
                setIsPop(true)
            }
        } else {
            setErr('Each cut must be between 1% and 100%')
        }

    }
    React.useEffect(() => {
        if (cutsPortfolios.length > 0 && sum(cutsPortfolios) >= 99) {
            for (let i = 0; i < cutsPortfolios.length; i++) {
                if (parseFloat(props.exchangePortfolios[i].portfolio_cut) * 100 !== cutsPortfolios[i]) {
                    handleChange(cutsPortfolios[i] - 2, i)
                    break;
                }
            }
        }
    }, [cutsPortfolios])

    const submit = async () => {
        for (let i = 0; i < cutsPortfolios.length; i++) {
            const port = props.exchangePortfolios[i]
            if (parseFloat(port.portfolio_cut) * 100 !== cutsPortfolios[i]) {
                const date = new Date(Date.now())
                let params = {
                    MessageAttributes: {
                        "timestamp": {
                            DataType: "String",
                            StringValue: date.toISOString().slice(0, 19).replace('T', ' ')
                        },
                        "id": {
                            DataType: "String",
                            StringValue: id.toString()
                        },
                        "portfolio": {
                            DataType: "String",
                            StringValue: port.portfolio
                        },
                        "cut": {
                            DataType: "String",
                            StringValue: (cutsPortfolios[i] / 100).toString()
                        },
                        "exchange": {
                            DataType: "String",
                            StringValue: props.exchange
                        }
                    },
                    MessageBody: " edit active portfolio cut ",
                    QueueUrl: "edit_active_portfolios"
                }
                console.log(params)
                sendSqs(params.MessageAttributes, params.MessageBody, params.QueueUrl).then((res) => {
                    if (res.data.send){
                        localStorage.setItem('edited_' + props.exchange + '_' + port.portfolio, cutsPortfolios[i] / 100)
                        props.onLocalStorageUpdate()
                    }else{
                        console.log("Error");
                    }
                })

            }
            if (i === cutsPortfolios.length - 1){
                setIsShow(true)
                props.onLocalStorageUpdate()
            }
        }

    }

    return (<div className="background-add-modal">
        <div className="container-add-modal">
            <Title Title1="Edit your" Title2="Active Portfolio"/>
            <span className="text-black-16px" style={{opacity: .9, letterSpacing: 0.02 + 'rem'}}>
                    {`Adjust the portfolio cut for ${props.exchange}`}
            </span>
            <div className='edit-cuts'>
                {props.exchangePortfolios.map((port, index) => {
                    return <div className="new-cut">
                        <div className="sub-row">
                            <span className="text-black-16px" style={{fontWeight: 600}}>Portfolio:</span>
                            <span
                                className="text-black-16px">{port.portfolio.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
                        </div>
                        <div className="sub-row">
                            <span className="text-black-16px" style={{fontWeight: 600}}>Cut(%):</span>
                            <input
                                className="input-data-portfolio text-black-16px"
                                type="number"
                                id="portfolio_cut"
                                name="portfolio_cut"
                                style={{width: '4.4em'}}
                                defaultValue={(port['portfolio_cut'] * 100).toFixed(1)}
                                onChange={(e) => handleChange(e.target.value, index)}
                            />


                        </div>
                    </div>
                })}
            </div>
            <small className="text-danger ">
                {err}
            </small>
            <small className="text-black-14px">
                {msg}
            </small>
            <div className="row-modal">
                <button className="back-button text-white-16px"

                        onClick={() => props.setPop(false)}>Back
                </button>
                <button className="next-button text-white-16px"
                        onClick={() => validate()}>Save
                </button>
            </div>
            {isShow && <ModalQueue setPop={setIsShow} action={props.setPop}/>}
        </div>
        {isPop && <Modal content={"If you continue all data will be overwritten, do you want to continue ?"}
                         setPop={setIsPop} action={submit} notClose={true}/>
        }
    </div>)
}

export default EditActivePortfolio