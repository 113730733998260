import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import "./OnHold.css"
import Header from "../Header/Index.tsx";
import { setGlobalState, useGlobalState } from "../../App";
import { createGlobalState } from "react-hooks-global-state";


export default function V1() {

    const navigate = useNavigate()
    let msg = 'We have successfully verified your email.' +
        '</br>' +
        ' Please wait for your Account to be activated. We will email you as soon as your account is ready to use.' +
        '</br>' +
        'Thank you'

    return (<>
        <Header />
        <div className="background-onhold">
            <div className="on-hold-container">
                <span className="text-black-32px">Email Verified</span>
                <span
                    className="text-black-16px" style={{ opacity: '.8' }}>We have successfully verified your email.</span>
                <span
                    className="text-black-16px" style={{ opacity: '.8' }}> Please wait for your Account to be activated. We will email you as soon as your account is ready to use. It can take up to 1 minutes</span>
                <span
                    className="text-black-16px" style={{ opacity: '.8' }}>Thank you</span>
                <button className="btn-onhold text-white-16px" onClick={() => {
                    navigate("/", { replace: true })
                }}>Back to Homepage
                </button>
            </div>
        </div>

    </>
    )
}