import React, { useEffect } from 'react'
import ProductTab from "../Products/ProductTab";
import Header from "../Header/Index.tsx";
import Footer from "../Footer/Index.jsx";
import { getVideosSupport } from "../../services/api";
import ReactPlayer from 'react-player'

//import video1 from './videos/business_model.mp4'


const Support = () => {
    const styleBox = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1em'
    }



    return (
        <>
            <Header />
            <div className="products" style={{ marginTop: '10em', marginBottom: '5em' }}>
                <div className="title-products">
                    <span className="text-black-50px">Support</span>
                    <span className="text-cyan-50px">Page</span>
                </div>
                <span className="description-products text-black-16px">Here you can find all the videos or content that you need to fully understand the functionality of the
                    platform. We recommend you to take a look to our videos before start operating. If you any doubt or problems don't hesitate to reach us to our email: support@aidvisory.ai</span>
                <div className="products-tabs" style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div style={styleBox}>
                        <span className='text-black-16px'>Business Model:</span>
                        <ReactPlayer url='https://www.youtube.com/watch?v=DMfrR2ehmq8' controls />
                    </div>
                    <div style={styleBox}>
                        <span className='text-black-16px'>Platform Walk-through:</span>
                        <ReactPlayer url='https://www.youtube.com/watch?v=J8LrXxEfMtU' controls />
                    </div>
                    <div style={styleBox}>
                        <span className='text-black-16px'>Bybit Tutorial:</span>
                        <ReactPlayer url='https://www.youtube.com/watch?v=l3vdJVdSoBw' controls />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Support;