import React from 'react';
import "../PlatformTrader/PlatformTrader.css"
import "./Payments.css"
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import GridPayments from "./GridPayments";


const Payments = () => {
    return (
        <div className='background'>
            <HeaderPlatform/>
            <div className="col-platform">
                <GridPayments/>
            </div>
        </div>
    )
}

export default Payments