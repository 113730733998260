import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../App";


const NavBar = () => {

    const username = useGlobalState('username')[0]
    const isTrader = useGlobalState('isTrader')[0];

    const [selectedPage, setSelectedPage] = React.useState("Dashboard")
    const [pages, setPages] = React.useState([{
        id: 1,
        name: "Dashboard",
        ref: "/dashboard_" + (isTrader ? "trader" : "investor") + "/user=" + username
    }, {
        id: 2,
        name: "Settings",
        ref: "/dashboard_" + (isTrader ? "trader" : "investor") + "/settings"
    }]);

    React.useEffect(() => {
        if (isTrader)
            setPages([...pages, {
                id: 3,
                name: "Live Trading",
                ref: "/dashboard_trader/live_trading"
            }])
        else
            setPages([...pages, /*{
                id: 3,
                name: "Payments",
                ref: "/dashboard_investor/payments"
            },*/ {
                id: 4,
                name: "Subscriptions",
                ref: "/dashboard_" + (isTrader ? "trader" : "investor") + "/subscriptions"
            }])
    }, [])


    return (
        <div className="navbar-platform">
            <span className="text-white-16px">Menù</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {pages.map((item) => (
                    <Link to={item['ref']}
                        className={"navbar-item text-white-16px " + (selectedPage === item['name'] ? 'navbar-active' : '')}
                        onClick={() => setSelectedPage(item['name'])} key={item['id']}
                    >{item['name']}
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default NavBar;

