import React from "react";
import "./Footer.css";


const Footer = () => {
    return(
        <div className="footer">
            <div className="col">
                <span className="text-white-32px">AIdvisory LTD</span>
                <a className="text-white-16px" href="">Privacy policy</a>
            </div>
            <div className="col">
                <span className="text-white-24px">Contact Us</span>
                <a target={"_blank"} href={"mailto:info@AIdvisory.ai"}><span className="text-white-16px">info@AIdvisory.ai</span></a>
                <span className="text-white-16px">17 King Edwards Road, Ruislip, London</span>
            </div>
            <div className="col">
                <span className="text-white-24px">Find us on social</span>
                <div className="row">
                    <img className="icon-footer" src={"./img/facebook.png"} alt=""/>
                    <a className="text-white-16px" href="https://www.facebook.com/aidvisory">Facebook</a>
                </div>
                <div className="row" >
                    <img className="icon-footer" src={"./img/instagram.png"} alt=""/>
                    <a className="text-white-16px" href="https://www.instagram.com/aidvisory_ltd/?igshid=MmIzYWVlNDQ5Yg%3D%3D">Instagram</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;