import React from "react";
import "./Hero.css";
import GetStarted from "../GetStarted/Index.jsx"
import {Link} from "react-router-dom";


const Hero = (props) => {
    return (<>
        <div id="Home"/>
        <div className="hero">

            <div className="group-hero">
                <div className="title-hero">
                    <span className="text-black-50px">{props.title1}</span>
                    <span className="text-cyan-50px">{props.title2}</span>
                </div>
                <span className="description-hero text-black-16px">{props.descriptionHero}</span>

                <div className="flex-row">
                    <GetStarted/>
                    <Link to="/user_login" className="or-login text-black-16px">or Login</Link>
                </div>

            </div>
            <div className="tabs-group-hero">
                <div className="tab-hero">
                    <span className="text-black-16px text-right">{props.descriptionTab1}</span>
                    <img className="icon" src={"./img/layers-icon.png"} alt=""/>
                </div>
                <div className="tab-hero">
                    <img className="icon" src={"./img/cpu.png"} alt=""/>
                    <span className="text-black-16px text-left">{props.descriptionTab2}</span>
                </div>
                <div className="tab-hero">
                    <span className="text-black-16px text-right">{props.descriptionTab3}</span>
                    <img className="icon" src={"./img/web-browser.png"} alt=""/>
                </div>
            </div>
        </div>
    </>);
}

export default Hero;