import * as React from 'react';
import { useState, useEffect } from 'react';
import { Subscription } from "../interfaces.ts";
import { useNavigate } from "react-router-dom";

const SubscriptionSuccessModal = ({ subscription,open:isOpen,onClose }: { subscription?: Subscription ,open: boolean,onClose: () => void}) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);

    const closeModal = () => {
        navigate("/dashboard_investor/settings")
        onClose();
    }

    if (!isOpen||!subscription) return null;

    return (
        <>
            <style>
                {`
          .open-modal-btn {
            background: linear-gradient(to right, #8B5CF6, #6366F1);
            color: white;
            font-weight: bold;
            padding: 12px 24px;
            border-radius: 9999px;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          }

          .open-modal-btn:hover {
            transform: translateY(-2px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
          }

          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            animation: fadeIn 0.3s ease-out;
          }

          .modal-content {
            background-color: white;
            border-radius: 16px;
            box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            width: 90%;
            max-width: 500px;
            position: relative;
            animation: slideUp 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          }

          .modal-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
          }

          .blob {
            position: absolute;
            border-radius: 50%;
            filter: blur(40px);
            opacity: 0.7;
            mix-blend-mode: multiply;
            animation: float 10s infinite ease-in-out;
          }

          .blob-1 { background-color: #4e8098; top: -50px; left: -50px; width: 200px; height: 200px; }
          .blob-2 { background-color: #4e80ff; top: 50%; right: -30px; width: 150px; height: 150px; }
          .blob-3 { background-color: #4effffaa; bottom: -30px; left: 20%; width: 180px; height: 180px; }
          .blob-4 { background-color: #3B82F6; top: 30%; left: -20px; width: 120px; height: 120px; }
          .blob-5 { background-color: #10B981; bottom: 20%; right: 10%; width: 160px; height: 160px; }

          .modal-body {
            position: relative;
            padding: 32px;
            text-align: center;
          }

          .success-icon {
            background-color: #10B981;
            color: white;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            margin: 0 auto 20px;
            animation: popIn 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.2s both;
          }

          .modal-title {
            font-size: 24px;
            font-weight: bold;
            color: #1F2937;
            margin-bottom: 8px;
          }

          .modal-subtitle {
            font-size: 16px;
            color: #6B7280;
            margin-bottom: 24px;
          }

          .subscription-details {
            text-align: left;
            margin-bottom: 24px;
          }

          .detail-item {
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 8px;
            padding: 12px;
            margin-bottom: 12px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            animation: slideIn 0.5s ease-out both;
          }

          .detail-icon {
            font-size: 24px;
            margin-right: 12px;
          }

          .detail-title {
            font-weight: bold;
            color: #1F2937;
            margin-bottom: 4px;
          }

          .detail-description {
            font-size: 14px;
            color: #6B7280;
          }

          .close-modal-btn {
            background: #4e8098;
            color: white;
            font-weight: bold;
            padding: 12px 24px;
            border-radius: 9999px;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          }

          .close-modal-btn:hover {
            transform: translateY(-2px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes slideUp {
            from { transform: translateY(20px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }

          @keyframes popIn {
            from { transform: scale(0.8); opacity: 0; }
            to { transform: scale(1); opacity: 1; }
          }

          @keyframes slideIn {
            from { transform: translateX(-20px); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }

          @keyframes float {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }

          .detail-item:nth-child(1) { animation-delay: 0.1s; }
          .detail-item:nth-child(2) { animation-delay: 0.2s; }
          .detail-item:nth-child(3) { animation-delay: 0.3s; }
          .detail-item:nth-child(4) { animation-delay: 0.4s; }
        `}
            </style>
            <div className="modal-overlay" onClick={closeModal}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-background">
                        {[...Array(3)].map((_, i) => (
                            <div key={i} className={`blob blob-${i + 1}`}></div>
                        ))}
                    </div>
                    <div className="modal-body">
                        <div className="success-icon">✓</div>
                        <h2 className="modal-title">Subscription {subscription.name} Actived!</h2>
                        <p className="modal-subtitle">Welcome to the world of advanced trading</p>

                        <div className="subscription-details">
                            <div className="detail-item">
                                <span className="detail-icon">⭐</span>
                                <div>
                                    <p className="detail-title">{subscription.name} Plan</p>
                                    <p className="detail-description">{subscription.cost} for {subscription.time} {subscription.time === 1 ? 'month' : 'months'}</p>
                                </div>
                            </div>
                            {subscription.access && (
                                <div className="detail-item">
                                    <span className="detail-icon">🔑</span>
                                    <div>
                                        <p className="detail-title">Access</p>
                                        <p className="detail-description">{subscription.access}</p>
                                    </div>
                                </div>
                            )}
                            {subscription.extra && (
                                <div className="detail-item">
                                    <span className="detail-icon">⚡</span>
                                    <div>
                                        <p className="detail-title">Extra</p>
                                        <p className="detail-description">{subscription.extra}</p>
                                    </div>
                                </div>
                            )}
                            {subscription.details && subscription.details.portfolioStrategies && (
                                <div className="detail-item">
                                    <span className="detail-icon">📈</span>
                                    <div>
                                        <p className="detail-title">Strategies</p>
                                        <p className="detail-description">{subscription.details.portfolioStrategies}</p>
                                    </div>
                                </div>
                            )}
                            {subscription.freeTrial && (
                                <div className="detail-item">
                                    <span className="detail-icon">🎁</span>
                                    <div>
                                        <p className="detail-title">Free Trial Duration</p>
                                        <p className="detail-description">{subscription.freeTrial}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <button onClick={closeModal} className="close-modal-btn">
                            Start Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionSuccessModal;