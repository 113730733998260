import axios from 'axios'
import {setAuthToken} from "./api";
import {setGlobalState} from "../App";

const url ="https://aidvisory-v2-93714a282011.herokuapp.com"; // https://aidvisorybackend.herokuapp.com/ || https://aidvisory-test.herokuapp.com/
const api = axios.create({
    baseURL: url,
    timeout: 60000
})

export const login = (credentials) => api.post(`auth`,credentials)
export const createToken = (credentials) => api.post(`oauth/token`,credentials)

export const register = (credentials) => api.post(`register`,credentials)

export const logout = () => {
    setAuthToken(null)
    setGlobalState('loggedIn', false)
    setGlobalState("username", "")
    setGlobalState("userStrategies", [])
    setGlobalState("password", "")
    setGlobalState("IdUser", "")
    setGlobalState("emailUser", '')
    setGlobalState("nameUser", '')
    setGlobalState("surnameUser", '')
    setGlobalState("token", '')
    setGlobalState("apiKeyInvestor", [])
    setGlobalState("activeAddr", 'Connect Wallet')
    setGlobalState("web3", null)
    localStorage.clear()
}