import axios from "axios";

const url =
  "https://aidvisory-v2-93714a282011.herokuapp.com"; // https://aidvisorybackend.herokuapp.com/ || https://aidvisory-test.herokuapp.com/
const api = axios.create({
    baseURL: url,
    timeout: 90000,
});
const BASE_URL = url
export {BASE_URL}
export const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common.Authorization;
    }
};

export const updateStatusPayment = (id) =>
    api.post(`updateStatusPayment`, {
        id,
    });
export const getDataLanding = () => api.get(`get_data_landing`);

export const getVideosSupport = () => api.get(`get_videos_support`);

export const getApiKeyInvestor = (user) =>
    api.get(`get_api_key_investor?username=${user}`);

export const getStatusApiKeyInvestor = (
    publicKey,
    privateKey,
    exchange,
    tradingPass,
    id,
) =>
    api.get(
        `get_status_api_key_investor?public=${publicKey}&private=${privateKey}&exchange=${exchange}&tradingPass=${tradingPass}&id=${id}`,
    );

export const getActivePortfolio = (id) =>
    api.get(`get_active_portfolio?id=${id}`);

export const getAllPortfolios = () => api.get(`get_all_portfolios`);

export const getDataGlobalPnl = (id) => api.get(`get_data_global_pnl?id=${id}`);

export const getOpenPositions = (id) =>
    api.get(`get_open_positions?IdUser=${id}`);

export const getAll = (user, email) =>
    api.get(`get_all_username&email?username=${user}&email=${email}`);

export const sendHelpTicket = (user, email, object, message, isTrader) =>
    api.post(`send_help_ticket`, {
        username: user,
        email,
        object,
        message,
        isTrader,
    });

export const forgotPassword = (email, isTrader) =>
    api.post(`forgot_password`, {
        email,
        isTrader,
    });

export const checkTokenReset = (token, id, isTrader) =>
    api.get(`check_token_reset?token=${token}&id=${id}&isTrader=${isTrader}`);

export const resetPassword = (password, username, isTrader, token) =>
    api.post(`reset_password`, {
        password,
        username,
        isTrader,
        token,
    });

export const getPaymentLink = (name, amount, id) =>
    api.get(`create_payment?name=${name}&amount=${amount}&id=${id}`);

export const sendSqs = (MessageAttributes, MessageBody, QueueUrl) =>
    api.post(`sendSQS`, {
        MessageAttributes,
        MessageBody,
        QueueUrl,
    });

export const sendCustomVerificationEmail = (email) =>
    api.post(`sendCustomVerificationEmail`, {
        email,
    });

export const getPaymentById = (id) => api.get(`paymentsById?id=${id}`);

export const checkUserBlacklist = (id) =>
    api.get(`checkUserBlacklist?id=${id}`);

export const checkReferallCode = (code, id) =>
    api.get(`checkReferallCode?code=${code}&id=${id}`);

// Trader
export const getNewComposition = (namePortfolio, user) =>
    api.get(`get_composition?namePortfolio=${namePortfolio}&user=${user}`);

export const getNewStrategies = (user) =>
    api.get(`get_strategies?user=${user}`);

export const getNewPortfolios = (user) =>
    api.get(`get_portfolios?user=${user}`);

export const getAllPortofolios = () => api.get(`get_all_portfolios`);

export const updatePortfolioAdd = (
    addableStrat,
    user,
    namePortfolio,
    composition,
    isLive,
    risk,
    info,
) =>
    api.post(`update_portfolio_add`, {
        toAddStrat: addableStrat,
        user,
        namePortfolio,
        composition,
        isLive,
        risk,
        info,
    });

export const deletePortfolio = (strategyList, user, namePortfolio) =>
    api.post(`delete_portfolio`, {
        strategyList,
        user,
        namePortfolio,
    });

export const saveFutures = (isFutures, user, nameStrategy) =>
    api.post(`save_futures`, {
        isFutures,
        user,
        strategy: nameStrategy,
    });

export const saveDataPortofolio = (
    compositionList,
    user,
    namePortfolio,
    risk,
    info,
) =>
    api.post(`save_new_data`, {
        compositionList,
        user,
        namePortfolio,
        risk,
        info,
    });

export const saveNewPortfolio = (
    strategyList,
    namePortfolio,
    user,
    risk,
    info,
) =>
    api.post(`save_new_portfolio`, {
        strategyList,
        user,
        namePortfolio,
        risk,
        info,
    });

export const getOldHash = (user) =>
    api.post(`get_old_hash_pass`, {
        username: user,
    });
export const checkSession = () => api.get(`checkSession`);
export const updateNewPassword = (user, password) =>
    api.post(`update_password`, {
        user,
        password,
    });

export const launchPortfolio = (user, namePortfolio, isLive) =>
    api.post(`launch_portfolio`, {
        user,
        namePortfolio,
        isLive,
    });
// addresses
export const getAddresses = (userID) => api.get(`addresses/${userID}`);

export const addAddress = (address) =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        api.post(`addresses`, address)
            .then((res) => {
                resolve(res?.data);
            })
            .reject(reject);
    });

export const putAddress = (userId, address) =>
    api.put(`addresses/${userId}`, address);
