import React from "react";
import "./YourPortfolio.css";
import Axios from "axios";
import CustomizeButton from "../CustomizeButton/Index.jsx";
import StrategyComposition from "./StrategyComposition.jsx";
import { useGlobalState } from "../../App";
import ManagementButtons from "../ManagementButtons/Index.jsx";
import Modal from "../Modal/Index.jsx";
import AddStrategyModal from "../AddStrategyModal/Index.jsx";
import Dropdown from "../Dropdown/Index.jsx";
import {
    deletePortfolio,
    getNewComposition,
    launchPortfolio,
    saveDataPortofolio,
} from "../../services/api";

const Portfolio = (props) => {
    const riskList = [{ name: "Low" }, { name: "Mid" }, { name: "High" }];

    const user = useGlobalState("username")[0];

    const { namePortfolio } = props;

    const [compositionList, setCompositionList] = React.useState([]);
    const [copyCompList, setCopyCompList] = React.useState([]);
    const [err, setErr] = React.useState();
    const [msg, setMsg] = React.useState();
    const [isEditable, setIsEditable] = React.useState(false);
    const [isResetPop, setIsResetPop] = React.useState(false);
    const [isSavePop, setIsSavePop] = React.useState(false);
    const [isAddPop, setIsAddPop] = React.useState(false);
    const [isDeletePop, setIsDeletePop] = React.useState(false);
    const [isLive, setIsLive] = React.useState(false);
    const [risk, setRisk] = React.useState("");
    const [info, setInfo] = React.useState("");
    const [selectedRisk, setSelectedRisk] = React.useState("");
    const [openInfo, setOpenInfo] = React.useState(false);
    const getComposition = () => {
        setMsg("");
        getNewComposition(namePortfolio, user).then((response) => {
            if (response.data.err)
                setCompositionList([
                    {
                        strategy: "No strategies selected for this portfolio",
                        weight: "",
                    },
                ]);
            else {
                let appo;
                const appoRisk = response.data[0].risk;
                setIsLive(response.data.some((obj) => obj.is_live === 1));
                setRisk(appoRisk);
                setInfo(response.data[0].info);
                setSelectedRisk(
                    appoRisk.charAt(0).toUpperCase() + appoRisk.slice(1),
                );
                setCompositionList(
                    response.data.map((obj, index) => {
                        appo = { ...obj, disabled: false };
                        return appo;
                    }),
                );
                setCopyCompList(
                    response.data.map((obj, index) => {
                        appo = { ...obj, disabled: false };
                        return appo;
                    }),
                );
            }
        });
    };

    const saveNewData = async () => {
        const sumWeigths = compositionList
            .map((data) => !data.disabled && parseFloat(data.weight))
            .reduce((a, b) => a + b);

        if (sumWeigths === 1) {
            setErr("");
            saveDataPortofolio(
                compositionList,
                user,
                namePortfolio,
                selectedRisk,
                info,
            ).then((response) => {
                if (response.data.save) {
                    setMsg("New data saved correctly");
                    setCopyCompList(compositionList);
                    setIsEditable(false);
                    getComposition();
                } else {
                    setErr("New data not saved correctly");
                }
            });
        } else {
            setErr(
                `The sum of the sizing must be equal to 1. Current sum: ${sumWeigths}`,
            );
        }
    };

    const deleteData = () => {
        deletePortfolio(compositionList, user, namePortfolio).then(
            (response) => {
                if (response.data.save) {
                    setIsEditable(false);
                    setIsDeletePop(false);
                    props.getPortfolio();
                }
            },
        );
    };

    const reset = () => {
        setCompositionList(copyCompList);
    };

    const launch = () => {
        launchPortfolio(user, namePortfolio, isLive).then((response) => {
            if (response.data.save) {
                setIsEditable(false);
                getComposition();
            }
        });
    };

    React.useEffect(() => {
        getComposition();

        setMsg("");
    }, [namePortfolio, isAddPop]);

    console.log(info);
    return (
        <>
            <div className="portfolio-tab">
                <div className="group-1 text-black-16px">
                    <span style={{ minWidth: `${2}rem` }}>
                        {props.idNumber}
                    </span>
                    <span
                        style={{
                            maxWidth: `${20}rem`,
                            minWidth: `${15}rem`,
                            overflowWrap: "break-word",
                        }}
                    >
                        {props.namePortfolio
                            .split("_")
                            .map(
                                (word) => word[0].toUpperCase() + word.slice(1),
                            )
                            .join(" ")}
                    </span>
                </div>
                {props.idNumber && (
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "0.2em 1em",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            className="save-button text-white-16px"
                            id="launch"
                            style={{
                                color: "white",
                                borderRadius: "13px",
                                fontSize: "14px",
                                maxWidth: "9em",
                                backgroundColor: isLive ? "#3f8f3f" : "#c65151",
                            }}
                            onClick={() => launch()}
                        >
                            {isLive ? "Launched" : "Launch"}
                        </button>
                        <CustomizeButton
                            is={isEditable}
                            set={setIsEditable}
                            setMsg={setMsg}
                            light={false}
                        />
                    </div>
                )}
            </div>
            {isEditable && (
                <>
                    <div className="edit-area-portfolio">
                        <label
                            className="text-black-16px"
                            style={{ fontWeight: 700 }}
                        >
                            Portfolio composition:
                        </label>
                        <div className="composition">
                            {compositionList.map((strat, index) => (
                                <StrategyComposition
                                    key={index}
                                    index={index}
                                    name={strat.strategy}
                                    sizing={strat.weight}
                                    leverage={strat.leverage}
                                    setWeight={setCompositionList}
                                    newWeight={compositionList}
                                    disabled={strat.disabled}
                                />
                            ))}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: ".5em",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="add-strategies text-white-16px"
                                style={{ fontSize: "25px" }}
                                onClick={() => setIsAddPop(true)}
                            >
                                +
                            </div>
                            <Dropdown
                                contents={riskList}
                                selected={selectedRisk}
                                setSelected={setSelectedRisk}
                                padding={0.7}
                                width={7}
                                disabled={false}
                            />
                        </div>
                        <button
                            className="text-white-16px info-area-button"
                            onClick={() => setOpenInfo(!openInfo)}
                            style={{ alignSelf: "center", fontSize: "14px" }}
                        >
                            {openInfo ? "CLOSE INFO" : "EDIT INFO"}
                        </button>
                        {openInfo && (
                            <div className="info-area">
                                <textarea
                                    className="info-text-edit"
                                    value={info}
                                    placeholder="Insert a description"
                                    onChange={(e) => setInfo(e.target.value)}
                                />
                            </div>
                        )}
                        <ManagementButtons
                            setIsCancelPop={setIsResetPop}
                            setIsSavePop={setIsSavePop}
                            setDeletePop={setIsDeletePop}
                            setErr={setErr}
                            err={err}
                            msg={msg}
                        />
                    </div>
                    {isAddPop && (
                        <AddStrategyModal
                            setPop={setIsAddPop}
                            namePortfolio={props.namePortfolio}
                            compositionList={compositionList}
                            setCompositionList={setCompositionList}
                            getComposition={props.getPortfolio}
                            setIsEditable={setIsEditable}
                            isLive={isLive}
                            risk={risk}
                            info={info}
                        />
                    )}
                    {isResetPop && (
                        <Modal
                            content="Are you sure to reset to the previous settings ?"
                            setPop={setIsResetPop}
                            setIsEditable={setIsEditable}
                            action={reset}
                            notClose={false}
                            setErr={setErr}
                            setMsg={setMsg}
                        />
                    )}
                    {isSavePop && (
                        <Modal
                            content="If you save all data will be overwritten, do you want to continue ?"
                            setPop={setIsSavePop}
                            action={saveNewData}
                            notClose
                        />
                    )}
                    {isDeletePop && (
                        <Modal
                            content="This portfolio will be deleted, do you want to continue ?"
                            setPop={setIsDeletePop}
                            action={deleteData}
                            notClose
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Portfolio;
