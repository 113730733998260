import React from "react";
import "./Products.css";
import GetStarted from "../GetStarted/Index.jsx";

const ProductTab = (props) => {
    return (
        <div className="product-tab">
            <div className="title-plan text-white-32px">
                <span className="text-cyan-32px">{props.title}</span>
                <span className="text-black-32px">Plan</span>
            </div>
            <span className="description-product-tab text-black-16px">
                {props.description}
            </span>
            <GetStarted/>
        </div>
    );
}

export default ProductTab;