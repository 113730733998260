import React from "react";
import "./Settings.css";
import Axios from "axios";
import { useGlobalState } from "../../App";
import Modal from "../Modal/Index.jsx";
import { SendMessageCommand, SQSClient } from "@aws-sdk/client-sqs";
import ModalQueue from "../ModalQueue/Index.jsx";
import EditApiKeyModal from "../EditApiKeyModal/Index.jsx";
import { sendSqs } from "../../services/api";


const RowApi = (props) => {

    const [isShow, setIsShow] = React.useState(false);
    const [isPopDelete, setIsPopDelete] = React.useState(false);
    const [isPopEdit, setIsPopEdit] = React.useState(false);


    const user = useGlobalState("username")[0];
    const id = useGlobalState("IdUser")[0];

    const deleteApi = async () => {
        let params = {
            MessageAttributes: {
                "username": {
                    DataType: "String",
                    StringValue: user
                },
                "exchange": {
                    DataType: "String",
                    StringValue: props.exchange
                },
                "id": {
                    DataType: "String",
                    StringValue: id.toString()
                },
                "isFirst": {
                    DataType: "String",
                    StringValue: props.isFirst
                }
            },
            MessageBody: user + " 's api key ",
            QueueUrl: "delete_api_key_investor"
        };

        sendSqs(params.MessageAttributes, params.MessageBody, params.QueueUrl).then(res => {
            if (res.data.send) {
                console.log("success");
                setIsShow(true);
                localStorage.setItem("removed_" + props.exchange, props.exchange);
                props.onLocalStorageUpdate();
            } else {
                console.log("Error");
            }
        });

    };


    return (
        <>
            <div className="row-api" style={{ backgroundColor: props.disabled && "#e0e0e060" }}>
                <div style={{ display: "flex", columnGap: ".5em", alignItems: "center" }}>
                     <span className="text-black-16px"
                           style={{
                               letterSpacing: 0.02 + "rem",
                               fontWeight: 600
                           }}>{props.exchange}</span>
                    <div
                        className="status-api-key"
                        style={{ backgroundColor: props.is_active === 1 ? "#73CE62FF" : "#ff5858" }}
                    />
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: ".7rem",
                    flexWrap: "wrap"
                }}>

                    <button className="text-white-16px edit-pen-button" disabled={props.disabled}
                            onClick={() => setIsPopEdit(true)}><img className="edit-img" src="./img/edit.png" />
                    </button>
                    <button className="text-white-16px delete-api-button" disabled={props.disabledAll || props.disabled}
                            onClick={() => {
                                setIsPopDelete(true);
                            }}>Delete
                    </button>
                </div>
            </div>
            {isShow && <ModalQueue setPop={setIsShow} />}
            {isPopDelete && <Modal content={"If you continue all data will be deleted, do you want to continue ?"}
                                   setPop={setIsPopDelete} action={deleteApi} notClose={true} />
            }
            {isPopEdit && <EditApiKeyModal exchange={props.exchange} onLocalStorageUpdate={props.onLocalStorageUpdate}
                                           setPop={setIsPopEdit} />}
        </>
    );
};


export default RowApi;