/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable */
import "./App.css";
import React, { useCallback, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { createGlobalState } from "react-hooks-global-state";
import { CircularProgress, ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./components/LandingPage/Index.jsx";
import PlatformTrader from "./components/PlatformTrader/Index.jsx";
import Login from "./components/Login/Index.jsx";
import SignUp from "./components/SignUp/Index.jsx";
import Menu from "./components/Menu/Index.jsx";
import SetupLive from "./components/SetupLive/Index.jsx";
import PlatformInvestor from "./components/PlatformInvestor/Index.jsx";
import Settings from "./components/Settings/Index.jsx";
import OnHoldV1 from "./components/OnHold/V1.jsx";
import OnHoldV2 from "./components/OnHold/V2.jsx";
import ResetPassword from "./components/ResetPassword/Index.jsx";
import Support from "./components/Support/Support";
import Payments from "./components/Payments/Index.jsx";
import { checkSession, setAuthToken } from "./services/api.js";
import Subscriptions from "./components/Subscriptions/index.tsx";
import NotFound from "./components/NotFound/index.tsx";
import SubscrioptionDashboard from "./components/SubscriptionDashboard/index.tsx";
import QRCodeGenerator from "./components/Subscriptions/QrCode/QrCodeSubscriptions";

const { useGlobalState, setGlobalState } = createGlobalState({
  loggedIn: false,
  isTrader: false,
  username: "",
  name: "",
  userStrategies: [],
  password: "",
  IdUser: "",
  emailUser: "",
  nameUser: "",
  surname: "",
  surnameUser: "",
  token: "",
  apiKeyInvestor: [],
  supportedExchanges: ["binance", "kucoin", "kucoinfutures", "bybit"],
  activeAddr: "Connect Wallet",
  web3: null,
  modal: null,
  hideConn: true,
  checkedLogin: false,
  loading: false,
  loggedDate: 0,
});

const App = () => {
  const user = useGlobalState("username")[0];
  const loggedIn = useGlobalState("loggedIn")[0];
  const isTrader = useGlobalState("isTrader")[0];
  const checkedLogin = useGlobalState("checkedLogin")[0];

  const checkBackEndSession = useCallback(async () => {
    const localSession = localStorage.getItem("sessionData");
    try {
      const sessionData = JSON.parse(localSession);
      setAuthToken(sessionData.token);
      Object.keys(sessionData).forEach((key) => {
        setGlobalState(key, sessionData[key]);
      });
      setAuthToken(sessionData.token);
      await checkSession();
      setGlobalState("loggedIn", true);
    } catch (err) {
      console.error(err);
    } finally {
      setGlobalState("checkedLogin", true);
    }
  }, []);

  useEffect(() => {
    checkBackEndSession()
      .then(() => { })
      .catch((err) => console.error(err));
  }, [checkBackEndSession]);

  const investorDashboard = () => (
    <Route path="dashboard_investor" element={<Menu />}>
      <Route path={`user=${user}`} element={<PlatformInvestor />} />
      <Route path="settings" element={<Settings />} />
      <Route path="payments" element={<Payments />} />
      <Route path="subscriptions" element={<SubscrioptionDashboard />} />
    </Route>
  );
  const traderDashboard = () => (
    <Route path="dashboard_trader" element={<Menu />}>
      <Route path={`user=${user}`} element={<PlatformTrader />} />
      <Route path="live_trading" element={<SetupLive />} />
      <Route path="settings" element={<Settings />} />
    </Route>
  );
  if (!checkedLogin) {
    return (
      <div className="loading-container">
        <CircularProgress isIndeterminate />
      </div>
    );
  }
  const switchProtectedRoutes = () => {
    if (!loggedIn) {
      return null;
    }
    if (isTrader) {
      return traderDashboard();
    }
    return investorDashboard();
  };
  return (
    <ChakraProvider>
      <HashRouter>
        <Routes>
          <Route path="qrCode" element={<QRCodeGenerator />} />
          <Route path="/" element={<LandingPage />} />
          {switchProtectedRoutes()}
          <Route
            path="user_login"
            element={<Login isTrader={false} />}
          />
          <Route path="trader_login" element={<Login isTrader />} />
          <Route path="sign_up" element={<SignUp />} />
          <Route path="on-hold" element={<OnHoldV1 />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="on-hold-email" element={<OnHoldV2 />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="reset-password/:id/:token/:isTrader"
            element={<ResetPassword />}
          />
          <Route path="support" element={<Support />} />
        </Routes>
      </HashRouter>
    </ChakraProvider>
  );
};

export default App;
export { useGlobalState, setGlobalState };
