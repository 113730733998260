import React from "react";
import "./Dropdown.css";


const Item = (props) => {

    return (<>
        <div className="row-sub">
            <button className={'dropdown-item ' + (props.tag ? 'tag-active' : 'tag-inactive')} onClick={!props.sub ? e => {
                    props.setSelected(props.name);
                    props.setIsActive(false);
                    if (props.symbol) props.setSymbol(props.symbol)
                }
                : e => {
                    props.setContentData(props.sub);
                    props.setSubActive(true)
                }} disabled={props.isDisabled}>
                {props.tag ? (<span className="dropdown-tag montserrat-semi-bold-black-16px">{props.tag}</span>) : null}
                <span style={{overflowWrap: 'anywhere'}} className="text-black-16px">{props.name}</span>
                {props.sub ? (<img className="icon-dropdown" alt="" src={"./img/down-arrow.png"}></img>) : null}
            </button>
        </div>
    </>);

}

export default Item;