import * as React from "react";
import { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Hamburger from "../Hamburger/Index.jsx";
import { useGlobalState } from "../../App";

const contents = [
    {
        id: 1,
        name: "User Login",
        href: "/user_login",
    },
    {
        id: 2,
        name: "Trader Login",
        href: "/trader_login",
    },
    {
        id: 3,
        name: "Support",
        href: "/support",
    },
];
const loggedContents = (isTrader: boolean) => [
    {
        id: 1,
        name: "Dashboard",
        href: isTrader ? "/dashboard_trader" : "/dashboard_investor",
    },
    {
        id: 2,
        name: "Logout",
        href: "/",
    },
    {
        id: 3,
        name: "Support",
        href: "/support",
    },
];
const Header = () => {
    const [menuContent, setMenuContent] = useState(contents);
    const loggedIn = useGlobalState("loggedIn")[0];
    const isTrader = useGlobalState("isTrader")[0];

    const [widthScreen, setWidthScreen] = useState(window.innerWidth);

    const detectSize = () => {
        setWidthScreen(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [widthScreen]);

    useEffect(() => {
        if (loggedIn) {
            setMenuContent(loggedContents(isTrader));
        }
    }, [loggedIn, isTrader]);
    return (
        <div className="header">
            <Link to="/" className="logo-header">
                <picture>
                    <source
                        className="logo-img"
                        media="(max-width:1040px)"
                        srcSet="./img/LogoTextWhite.png"
                    />
                    <img
                        alt="logo"
                        className="logo-img"
                        src="./img/logoCyan.png"
                    />
                </picture>
            </Link>
            <nav className="navbar">
                <HashLink smooth to="#AboutUs" className="text-cyan-16px">
                    About Us
                </HashLink>
                <HashLink smooth to="#Products" className="text-cyan-16px">
                    Products
                </HashLink>
                <HashLink smooth to="#Analytics" className="text-cyan-16px">
                    Analytics
                </HashLink>
            </nav>
            <div>
                <Hamburger contents={menuContent} />
            </div>
        </div>
    );
};
export default Header;
