import React from "react";
import "./SetupLive.css";
import { useGlobalState } from "../../App";
import {
  SSMClient,
  GetParameterCommand,
  PutParameterCommand,
} from "@aws-sdk/client-ssm";
import { useNavigate } from "react-router-dom";
import Title from "../Title/Index.jsx";
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import Row from "./Row.jsx";
import Modal from "../Modal/Index.jsx";
import Dropdown from "../Dropdown/Index.jsx";

const SetupLive = () => {
  const login = useGlobalState("loggedIn")[0];
  const strategyList = useGlobalState("userStrategies")[0];
  const isTrader = useGlobalState("isTrader")[0];
  const user = useGlobalState("username")[0];

  const [selectedStrategy, setSelectedStrategy] =
    React.useState("Select a strategy");
  const [entryPointList, setEntryPointList] = React.useState([{}]);
  const [takeProfitList, setTakeProfitList] = React.useState([{}]);
  const [stopLossList, setStopLossList] = React.useState([{}]);
  const [copyEPList, setCopyEPList] = React.useState([{}]);
  const [copyTPList, setCopyTPList] = React.useState([{}]);
  const [copySLList, setCopySLList] = React.useState([{}]);
  const [isResetPop, setIsResetPop] = React.useState(false);
  const [isUpdatePop, setIsUpdatePop] = React.useState(false);
  const [strategies, setStrategies] = React.useState([
    { id: 0, name: "You don't have strategies" },
  ]);
  const [isDisabled, setIsDisabled] = React.useState(
    selectedStrategy === "Select a strategy"
  );
  const navigate = useNavigate();

  const creds = {
    accessKeyId: "AKIAZ3YRKYDQSNYRTJUD",
    secretAccessKey: "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",
  };

  const client = new SSMClient({
    region: "eu-west-1",
    credentials: creds,
  });

  React.useEffect(() => {
    let commandGet;
    let getParams;
    let live_trading = false;

    setIsDisabled(selectedStrategy === "Select a strategy");
    strategyList.map((obj) => {
      if (obj["live_trading"] === 1) live_trading = true;
    });
    if (live_trading)
      setStrategies(
        strategyList.map((obj, index) => {
          if (obj["live_trading"] === 1) {
            return [...strategies], { id: index, name: obj["strategy"] };
          }
        })
      );
    if (selectedStrategy !== "Select a strategy") {
      const words = selectedStrategy.split("_");
      const path = `/${words[0]}_${words[1]}/${words[2].toLowerCase()}/${words
        .slice(3)
        .join("_")}`;

      getParams = {
        Name: path,
      };

      commandGet = new GetParameterCommand(getParams);
      client.send(commandGet).then(
        (data) => {
          let arr = data.Parameter.Value.split(/[;]+/);
            console.log(arr)
          if (!arr.length) {
            alert("Something went wrong, please try again later");
            return;
          }
          let entryPoint = arr[0].split(/[,]+/)
          let entryPointPerc = arr[1].split(/[,]+/)
          let takeProfit = arr[2].split(/[,]+/)
          let takeProfitPerc = arr[3].split(/[,]+/)
          let stopLoss = arr[4].split(/[,]+/)
          let stopLossPerc = arr[5].split(/[,]+/)
          setEntryPointList(
            entryPoint.map((ep, i) => ({ value: ep, perc: entryPointPerc[i] }))
          );
          setCopyEPList(
            entryPoint.map((ep, i) => ({ value: ep, perc: entryPointPerc[i] }))
          );
          setTakeProfitList(
            takeProfit.map((ep, i) => ({ value: ep, perc: takeProfitPerc[i] }))
          );
          setCopyTPList(
            takeProfit.map((ep, i) => ({ value: ep, perc: takeProfitPerc[i] }))
          );
          setStopLossList(
            stopLoss.map((ep, i) => ({ value: ep, perc: stopLossPerc[i] }))
          );
          setCopySLList(
            stopLoss.map((ep, i) => ({ value: ep, perc: stopLossPerc[i] }))
          );
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [selectedStrategy]);

  const reset = () => {
    setEntryPointList(copyEPList);
    setTakeProfitList(copyTPList);
    setStopLossList(copySLList);
  };

  const update = () => {
    const Ep = entryPointList.map((item) => item.value).join(",");
    const Tp = takeProfitList.map((item) => item.value).join(",");
    const Sl = stopLossList.map((item) => item.value).join(",");
    const EpPerc = entryPointList.map((item) => item.perc).join(",");
    const TpPerc = takeProfitList.map((item) => item.perc).join(",");
    const SlPerc = stopLossList.map((item) => item.perc).join(",");

    const words = selectedStrategy.split("_");
    const path = `/${words[0]}_${words[1]}/${words[2].toLowerCase()}/${words
      .slice(3)
      .join("_")}`;

    const putParams = {
      Name: path,
      Value: `${Ep};${EpPerc};${Tp};${TpPerc};${Sl};${SlPerc}`,
      Overwrite: true,
    };
    console.log(putParams.Value);
    const commandPut = new PutParameterCommand(putParams);

    client.send(commandPut).then(
      (data) => {
        setCopyEPList(
          entryPointList.map((item, i) => ({
            value: item.value,
            perc: entryPointList[i].perc,
          }))
        );
        setCopyTPList(
          takeProfitList.map((item, i) => ({
            value: item.value,
            perc: takeProfitList[i].perc,
          }))
        );
        setCopySLList(
          stopLossList.map((item, i) => ({
            value: item.value,
            perc: stopLossList[i].perc,
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDeleteEntry = (index) => {
    const list = [...entryPointList];
    list.splice(index, 1);
    setEntryPointList(list);
  };
  const handleDeleteTake = (index) => {
    const list = [...takeProfitList];
    list.splice(index, 1);
    setTakeProfitList(list);
  };
  const handleDeleteStop = (index) => {
    const list = [...stopLossList];
    list.splice(index, 1);
    setStopLossList(list);
  };

  return (
    <div className="background">
      <HeaderPlatform />
      <div className="col-setup">
        <div className="group-2">
          <Title Title1="Control " Title2="Panel" />
          <div className="buttons-setup">
            <Dropdown
              selected={selectedStrategy}
              setSelected={setSelectedStrategy}
              contents={strategies}
              padding={1.2}
              disabled={strategies[0].name === "You don't have strategies"}
            />
            <div
              style={{
                display: "flex",
                rowGap: 0.5 + "rem",
                flexWrap: "wrap",
                justifyContent: "space-around",
                width: 35 + "vw",
              }}
            >
              <button
                className="update-button text-white-16px"
                onClick={() => setIsUpdatePop(true)}
                disabled={isDisabled}
              >
                Update Value
              </button>
              <button
                className="reset-value-button text-white-16px"
                onClick={() => {
                  setIsResetPop(true);
                }}
                disabled={isDisabled}
              >
                Reset Value
              </button>
            </div>
          </div>
        </div>
        <div className="container-action">
          <div className="group">
            <div className="panel-list">
              <Title Title1="Set" Title2="Entry Point" />
              {entryPointList.map((obj, index) => (
                <Row
                  key={index}
                  i={index}
                  Title="Entry Point"
                  delete={handleDeleteEntry}
                  value={obj["value"]}
                  perc={obj["perc"]}
                  list={entryPointList}
                  setList={setEntryPointList}
                  isDisabled={isDisabled}
                />
              ))}
            </div>
            <button
              className="add-button text-white-16px"
              style={{
                fontSize: "25px",
                alignSelf: "flex-start",
              }}
              onClick={() =>
                setEntryPointList([...entryPointList, { value: 0, perc: 0 }])
              }
              disabled={isDisabled}
            >
              +
            </button>
          </div>
          <div className="group">
            <Title Title1="Set" Title2="Take Profit" />
            <div className="panel-list">
              {takeProfitList.map((obj, index) => (
                <Row
                  key={index}
                  Title="Take Profit"
                  delete={handleDeleteTake}
                  i={index}
                  value={obj["value"]}
                  perc={obj["perc"]}
                  list={takeProfitList}
                  setList={setTakeProfitList}
                  isDisabled={isDisabled}
                />
              ))}
            </div>
            <button
              className="add-button text-white-16px"
              style={{
                fontSize: "25px",
                alignSelf: "flex-start",
              }}
              onClick={() =>
                setTakeProfitList([...takeProfitList, { value: 0, perc: 0 }])
              }
              disabled={isDisabled}
            >
              +
            </button>
          </div>
          <div className="group">
            <Title Title1="Set" Title2="Stop Loss" />
            <div className="panel-list">
              {stopLossList.map((obj, index) => (
                <Row
                  key={index}
                  Title="Stop Loss"
                  delete={handleDeleteStop}
                  i={index}
                  value={obj["value"]}
                  perc={obj["perc"]}
                  list={stopLossList}
                  setList={setStopLossList}
                  isDisabled={isDisabled}
                />
              ))}
            </div>
            <button
              className="add-button text-white-16px"
              style={{
                fontSize: "25px",
                alignSelf: "flex-start",
              }}
              onClick={() =>
                setStopLossList([...stopLossList, { value: 0, perc: 0 }])
              }
              disabled={isDisabled}
            >
              +
            </button>
          </div>
        </div>

        {isResetPop && (
          <Modal
            content={"Are you sure to reset to the previous settings?"}
            setPop={setIsResetPop}
            action={reset}
            notClose={true}
          />
        )}
        {isUpdatePop && (
          <Modal
            content={"Are you sure to update all data?"}
            setPop={setIsUpdatePop}
            action={update}
            notClose={true}
          />
        )}
      </div>
    </div>
  );
};

export default SetupLive;
