import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import "./OnHold.css"
import Header from "../Header/Index.tsx";
import { setGlobalState, useGlobalState } from "../../App";
import { createGlobalState } from "react-hooks-global-state";


export default function V2() {

    const navigate = useNavigate()
    let msg = 'Thank you for joining AIdvisory, we have emailed you to the address you provided.' +
        '</br>' +
        ' Please verify yourself by clicking on the link in the email to proceed to activate your account'

    return (<>
        <Header />
        <div className="background-onhold">
            <div className="on-hold-container">
                <span className="text-black-32px">Verify your email</span>
                <span
                    className="text-black-16px" style={{ opacity: '.8' }}>Thank you for joining AIdvisory, we have send to you an email to the address you provided.</span>
                <span
                    className="text-black-16px" style={{ opacity: '.8' }}>Please verify yourself by clicking on the link in the email to proceed to activate your account</span>
                <button className="btn-onhold text-white-16px" onClick={() => {
                    navigate("/", { replace: true })
                }}>Back to Homepage
                </button>
            </div>
        </div>

    </>
    )
}