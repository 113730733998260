/* eslint-disable prettier/prettier */
import React from "react";
import "./Settings.css";
import { useGlobalState } from "../../App";
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import ChangePassword from "./ChangePassword";
import YourKey from "./YourKey";
import HelpForm from "./HelpForm";
import Referall from "./Referall";
import AddressForm from "../Subscriptions/AddressForm.tsx";
import Title from "../Title/Index.jsx";

const SettingsTrader = () => {
    const isTrader = useGlobalState("isTrader")[0];
    return (
        <div className="background">
            <HeaderPlatform />
            <div className="col-platform">
                {isTrader && <ChangePassword />}
                {!isTrader && (
                    <>
                        <div
                            className="row-settings"
                            style={{ alignItems: "normal" }}
                        >
                            <span
                                className="text-black-14px"
                                style={{
                                    textAlign: "end",
                                    width: "100%",
                                    opacity: ".8",
                                }}
                            >
                                If you need help, please contact us at our email
                                support@aidvisory.ai
                            </span>
                            <YourKey />
                        </div>

                        {/*<div className='key-tab'>*/}

                        {/*    <Title*/}
                        {/*        Title1="Change your"*/}
                        {/*        Title2="Billing address"*/}
                        {/*    />*/}
                        {/*    <AddressForm />*/}
                        {/*</div>*/}
                        <Referall />
                    </>
                )}

                <HelpForm />
            </div>
        </div>
    );
};

export default SettingsTrader;
