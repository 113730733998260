import React, { useEffect, useState } from "react";
import "./Settings.css";
import Title from "../Title/Index.jsx";
import RowApi from "./RowApi";
import AddApiKeyModal from "../AddApiKeyModal/Index.jsx";
import { setGlobalState, useGlobalState } from "../../App";
import { BASE_URL, getApiKeyInvestor } from "../../services/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../alert/CstomAlert";
import { CircularProgress } from "@chakra-ui/react";

const YourKey = () => {

    const [isAddPop, setIsAddPop] = React.useState(false);
    const apiKeyInvestor = useGlobalState("apiKeyInvestor")[0];
    const token = useGlobalState("token")[0];
    const supportedExchanges = useGlobalState("supportedExchanges")[0];
    const user = useGlobalState("username")[0];
    const id = useGlobalState("IdUser")[0];
    const [loading, setLoading] = useState(false);
    const [alertState, setAlertState] = useState({
        isOpen: false,
        title: "",
        message: ""
    });

    // Function to show the custom alert
    const showAlert = (title, message) => {
        setAlertState({
            isOpen: true,
            title,
            message
        });
    };

    // Function to close the custom alert
    const closeAlert = () => {
        navigate("/dashboard_investor/subscriptions");
        setAlertState({
            isOpen: false,
            title: "",
            message: ""
        });
    };
    const [addedApi, setAddedApi] = React.useState(supportedExchanges.filter(obj => {
        return localStorage.getItem("added_" + obj) === obj;
    }));
    const [disabledExchange, setDisabledExchange] = React.useState(supportedExchanges.filter(obj => {
        return localStorage.getItem("removed_" + obj) === obj;
    }));
    const [updatedApi, setUpdatedApi] = React.useState(supportedExchanges.filter(obj => {
        return localStorage.getItem("updated_" + obj) === obj;
    }));
    const [subscriptions, setSubscriptions] = useState([]);
    const [userPlan, setUserPlan] = useState(null);
    const userId = useGlobalState("IdUser")[0];
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_URL}/api/subscriptions`).then((response) => {
            setSubscriptions(response.data);
        });
    }, []);
    useEffect(() => {
        const fetchUserSubscription = async () => {
            setLoading(true);
            try {
                // Assuming you have the user's ID stored somewhere (e.g., in localStorage or in a global state)
                const response = await axios.get(`${BASE_URL}/api/user-subscriptions/${userId}`);
                if (response.data.length > 0) {
                    // Assuming the user has only one active subscription
                    const userSubscription = response.data[0];
                    const subscriptionDetails = subscriptions.find((sub) => sub.id === userSubscription.subscription_id);
                    setUserPlan(subscriptionDetails || null);
                }
            } catch (error) {
                console.error("Error fetching user subscription:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserSubscription();
    }, [subscriptions]);

    const [testBlock, setTestBlock] = React.useState(false);

    const handleLocalStorageUpdate = () => {
        setDisabledExchange(supportedExchanges.filter(obj => {
            return localStorage.getItem("removed_" + obj) === obj;
        }));
        setAddedApi(supportedExchanges.filter(obj => {
            return localStorage.getItem("added_" + obj) === obj;
        }));
        setUpdatedApi(supportedExchanges.filter(obj => {
            return localStorage.getItem("updated_" + obj) === obj;
        }));
    };
    const fetchApiKeyInvestor = async () => {
        try {
            const response = await getApiKeyInvestor(user);
            if (response.data[0].password) { // && JSON.stringify(response.data) !== JSON.stringify(apiKeyInvestor)
                setGlobalState("apiKeyInvestor", [...response.data]);
                console.log("fetch");
                setDisabledExchange(disabledExchange.filter(key => {
                    if (![...response.data].some(elem => elem.exchange === key)) {
                        localStorage.removeItem("removed_" + key);
                    }
                }));
                setAddedApi(addedApi.filter(key => {
                    if ([...response.data].some(elem => elem.exchange === key)) {
                        localStorage.removeItem("added_" + key);
                    }
                }));
                setUpdatedApi(updatedApi.filter(key => {
                    const indexOld = apiKeyInvestor.findIndex(api => api.exchange === key);
                    const indexNew = [...response.data].findIndex(api => api.exchange === key);

                    if (apiKeyInvestor[indexOld].key !== [...response.data][indexNew].key) {
                        localStorage.removeItem("updated_" + key);
                    }
                }));
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        fetchApiKeyInvestor();
    }, []);

    return (
        <>
            <div className="key-tab">
                <Title Title1="Take a look at" Title2="Your API Keys" />
                {(!(apiKeyInvestor.length === 0) && apiKeyInvestor[0].exchange) ? <>
                        <span className="text-black-16px">If the sum of the balances of all your wallets is over 500 USDT a green dot will appear next to the APIs. It can take up to 3 hours.</span>

                        <span
                            className="text-black-16px"
                            style={{
                                letterSpacing: 0.02 + "rem",
                                fontWeight: 600,
                                paddingTop: .8 + "rem"
                            }}>
                            Exchanges:
                        </span>
                        <div className="col-api">
                            {apiKeyInvestor.map((obj, index) => (
                                <RowApi
                                    key={index}
                                    exchange={obj["exchange"]}
                                    is_active={obj["is_active"]}
                                    isFirst={apiKeyInvestor.length === 1 ? "true" : "false"}
                                    disabledAll={disabledExchange.length > 0 || addedApi.length > 0}
                                    disabled={disabledExchange.includes(obj["exchange"]) || updatedApi.includes(obj["exchange"])}
                                    onLocalStorageUpdate={handleLocalStorageUpdate}
                                />
                            ))}
                        </div>
                    </> :
                    <span className="text-black-16px"
                          style={{
                              opacity: .9,
                              letterSpacing: 0.02 + "rem"
                          }}>You don't have registered any API Key yet</span>
                }
                <button className="text-white-16px next-button"
                        style={{ maxWidth: "14rem", alignSelf: "center", width: "100%" }}
                        disabled={loading || disabledExchange.length > 0}
                        onClick={() => {
                            /*if (id === 1 || id === 2 || id === 62 || id === 39 || id === 69 || id === 63
                                || id === 75 || id === 99 || id === 72 || id === 90 || id === 88 || id === 105)*/
                            if ((!userPlan || (apiKeyInvestor.filter(e => e.key).length + 1) > userPlan?.details?.exchange)) {
                                showAlert("Warning", "You have to subscribe a greater plan to add new API key");
                            } else
                                setIsAddPop(true);
                            /* else
                                 setTestBlock(true)*/
                        }}>{loading ?
                    <CircularProgress color={"white"} size={5}
                                      isIndeterminate /> : testBlock ? "Only for tester" : "Add new API key"}
                </button>
                {disabledExchange.length > 0 &&
                    <span className="text-black-14px" style={{ opacity: ".8" }}>We are processing your request, no further API operations will be possible until completed, thank you and sorry for the wait. </span>}
                {addedApi.length > 0 &&
                    <span className="text-black-14px" style={{ opacity: ".8" }}>We are processing your request, no further APIs can be deleted until completed, thank you and sorry for the wait. </span>}
            </div>
            {isAddPop && <AddApiKeyModal setPop={setIsAddPop} addedApi={addedApi}
                                         onLocalStorageUpdate={handleLocalStorageUpdate} />}
            {alertState.isOpen && (
                <CustomAlert
                    title={alertState.title}
                    message={alertState.message}
                    onClose={closeAlert}
                />
            )}
        </>

    );
};

export default YourKey;