import React from "react";
import "./Products.css";
import ProductTab from "./ProductTab.jsx"

const Products = (props) => {
    return (<>
        <div id="Products"/>
        <div className="products">
            <div className="title-products">
                <span className="text-black-50px">{props.title1}</span>
                <span className="text-cyan-50px">{props.title2}</span>
            </div>
            <span className="description-products text-black-16px">{props.descriptionProducts}</span>
            <div className="products-tabs">
                <ProductTab title="Managed" description={props.descriptionManaged}/>
                <ProductTab title="Manual" description={props.descriptionManual}/>
            </div>
        </div>

    </>);
}

export default Products;