import React from 'react';
import './ResetPassword.css';
import '../Login/Login.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import {checkTokenReset, resetPassword} from "../../services/api";

const ResetPassword = () => {

    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const {id, token, isTrader} = useParams()
    const navigate = useNavigate()

    const failure = (msg) => {
        setPasswordError(msg)
        setIsLoading(false)
    }

    const handleSubmit = (event) => {
        setIsLoading(true)
        event.preventDefault()
        setPasswordError('')

        if (newPassword === '' || confirmNewPassword === '') {
            failure('Missing Data')
        } else if (newPassword !== confirmNewPassword) {
            failure('Passwords do not match')
        } else if (!newPassword.match(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)) {
            failure("Password must contain at least one uppercase letter, one number, and be at least 8 characters long")
        }

        resetPassword(newPassword, id, isTrader, token).then(res => {
            if (res.data.success) {
                setIsLoading(false)
                alert('Password has been reset')
                navigate(isTrader ? '/trader_login' : '/user_login')
            } else {
                failure('Something went wrong')
            }
        })
    }

    React.useEffect(() => {
        checkTokenReset(token, id, isTrader).then(res => {
            if (!res.data.check) {
                alert('Invalid Token, unauthorized access')
                navigate('/')
            }
        })
    }, [])

    return (
        <div className="login">
            <Link to="/" style={{alignSelf: 'flex-start', padding: 1 + 'em'}}><img
                src="./img/LogoTextWhite.png" style={{width: 12 + 'rem'}}/></Link>
            <form className='col-login' onSubmit={handleSubmit}>
                <span className="text-black-50px">Reset Password</span>
                <div className="input-group">
                    <label className="text-black-16px">New Password</label>
                    <input
                        className="input-data text-black-16px"
                        aria-describedby="passwordHelp"
                        placeholder="Enter new password"
                        onChange={(event) => setNewPassword(event.target.value)}
                    />

                </div>
                <div className="input-group">
                    <label className="text-black-16px">Confirm New Password</label>
                    <input
                        className="input-data text-black-16px"
                        aria-describedby="confimrPasswordHelp"
                        placeholder="Confirm new password"
                        onChange={(event) => setConfirmNewPassword(event.target.value)}
                    />
                    <small id="confirmPasswordHelp" className="text-danger">
                        {passwordError}
                    </small>
                </div>
                <div className="button-group">
                    <button type="submit" className="submit-login text-white-16px" disabled={isLoading}
                            style={{padding: isLoading && '0.5em 1.5em'}}>
                        {isLoading ? <img style={{width: '2em'}} src='./img/loading.gif'/> : 'Save'}
                    </button>

                </div>
            </form>
        </div>

    )
}

export default ResetPassword