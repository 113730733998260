import * as React from "react";
import { FaUserCircle, FaTimes } from "react-icons/fa";
import RevolutCheckoutButton from "./RevolutCheckoutComponent.tsx";
import { useGlobalState } from "../../App";
import SuccessModal from "./SuccessModal/SuccessModal.tsx";
import { set } from "react-hook-form";

interface SubscriptionModalProps {
    open: boolean;
    userId: number | string;
    subscription: any;
    onClose: () => void;
    value: string;
}

const StripeSubscriptionModal: React.FC<SubscriptionModalProps> =
    ({
         userId,
         open,
         subscription,
         onClose,
         value
     }: SubscriptionModalProps) => {
        const [loggedIn] = useGlobalState("loggedIn");
        const [success, setSuccess] = React.useState<null | boolean>(null);
        const error = success === false;
        const [successModal, setSuccessModal] = React.useState(false);
        if (!open) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                <div className="bg-white rounded-2xl shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto relative"
                     onClick={(e) => e.stopPropagation()}>
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
                    >
                        <FaTimes size={24} />
                    </button>

                    <div className="p-6 pb-12">
                        <div className="flex justify-center mb-6">
                            <img src="./img/logoCyan.png" alt="Logo" className="w-48 h-auto" />
                        </div>

                        <h2 className="text-2xl font-bold text-center mb-6 text-sky-800">Confirm your subscription</h2>

                        <div className="bg-sky-50 rounded-lg p-4 mb-6">
                            <p className="text-sky-800 font-medium mb-2">Subscription: <span
                                className="text-sky-600">{subscription?.name}</span></p>
                            <p className="text-sky-800 font-medium mb-2">Duration: <span
                                className="text-sky-600">{subscription?.time} {subscription?.time === 1 ? "month" : "months"}</span>
                            </p>
                            <p className="text-sky-800 font-medium">Price: <span
                                className="text-sky-600">{(parseInt(value.replace("€", "")) * subscription?.time).toFixed(2)} €</span>
                            </p>
                        </div>

                        {loggedIn ? (
                            <RevolutCheckoutButton
                                userId={userId}
                                subscriptionId={subscription?.id}
                                amount={parseFloat(subscription.price) * 100 * subscription.time}
                                setSuccess={(success) => {
                                    setSuccess(success);
                                    setSuccessModal(success);
                                }}
                                description={subscription.duration}
                                currency={"EUR"} />
                        ) : (
                            <div className="text-center">
                                <p className="text-gray-600 mb-4">Log in to complete your subscription</p>
                                <button
                                    style={{ background: "#4e8098" }}
                                    onClick={() => window.location.href = "/#/user_login"}
                                    className="hover:bg-sky-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 flex items-center justify-center mx-auto">
                                    <FaUserCircle className="mr-2" />
                                    Log in
                                </button>
                            </div>
                        )}
                        <SuccessModal subscription={subscription} open={successModal}
                                      onClose={() => setSuccessModal(false)} />

                    </div>
                </div>
            </div>
        );
    };

export default StripeSubscriptionModal;