import React from "react"
import "./ManagementButtons.css"

const ManagementButtons = (props) => {

    const handleDelete = () => {
        props.setDeletePop(true)
        props.setErr("")
    }

    const handleCancel = () => {
        props.setIsCancelPop(true)
        props.setErr("")
    }

    const handleSave = () => {
        props.setIsSavePop(true)
        props.setErr("")
    }
    return (<>
            <small style={{color: "red"}} className="text-black-14px">{props.err}</small>
            <small className="text-black-14px">{props.msg}</small>
            <div className="management-buttons text-white-16px">
                <div className="delete-button" onClick={() => handleDelete()}>Delete</div>
                <div className="row-management-buttons">
                    <div className="reset-button" onClick={() => handleCancel()}>Reset</div>
                    <div className="save-button " onClick={() => handleSave()}>Save</div>
                </div>
            </div>
        </>

    )
}

export default ManagementButtons