import React, {useEffect, useState} from 'react'
import Title from "../Title/Index.jsx";
import '../Login/Login.css'
import '../Modal/Modal.css'
import {useGlobalState} from "../../App";
import {checkReferallCode} from "../../services/api";

export default function Referall() {
    const [referralCode, setReferralCode] = useState('')
    const [friendCode, setFriendCode] = useState('')
    const userId = useGlobalState('IdUser')[0]


    useEffect(() => {
        setReferralCode("AID" + userId)
    }, []);

    const handleChange = (e) => {
        setFriendCode(e.target.value)
    }
    const handleSubmit = () => {
        if (friendCode === '') {
            alert('Please insert a referral code')
        } else if (friendCode === referralCode) {
            alert('You can not use your own referral code')
        } else {
             checkReferallCode(friendCode, userId).then(res =>{
                 if (res.data.valid)
                        alert('Referral code inserted successfully')
                    else
                        alert(res.data.msg)
             })

        }
    }
    return (
        <div className='key-tab'>
            <Title Title1="Share your" Title2="referral code"/>
            <span className='text-black-16px'>Share your code with your friend to get a fees discount</span>
            <div style={{display: 'flex', gap: '1em', flexWrap: 'wrap', alignItems: 'center'}}>
                <span className="text-black-16px">Your code is: <span className='text-black-16px'
                                                                       style={{fontWeight: 600}}>{referralCode}</span></span>
                <input className="input-data text-black-16px" style={{padding: '.5em', width: '15em'}}
                       onChange={(e) => handleChange(e)} placeholder="Insert Friend's Referall Code"/>
                <button className="continue-button text-white-16px" style={{border: 'none', borderRadius: '8px'}}
                        onClick={() => handleSubmit()}>Confirm
                </button>
            </div>
        </div>
    )
}