import React from "react"
import "./CustomizeButton.css"

const CustomizeButton = (props) => {

    const color = props.is ? "custom" : null

    return (
        <div className={"custom-button " + color} style={{marginLeft:'0'}}onClick={() => {
            props.set(!props.is);
            props.is && (props.light ? props.submit() : props.setMsg(""))
        }}>
            <span className="text-white-16px">{props.is ? (props.light ? "Save" : "Hide") : "Customize"}</span>
        </div>
    )
}

export default CustomizeButton;
