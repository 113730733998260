import * as React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { Alert, AlertDescription, AlertTitle } from "@chakra-ui/react";
interface CustomAlertProps {
    title: string;
    message: string;
    onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ title, message, onClose }: CustomAlertProps) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <Alert backgroundColor={"white"} alignItems={"flex-start"} className="max-w-lg bg-white rounded-2xl flex flex-col justify-start">
                <AlertTitle className={"text-start text-2xl mb-5"}>{title}</AlertTitle>
                <AlertDescription>{message}</AlertDescription>
                <button
                    onClick={onClose}
                    style={{backgroundColor:"#4e8098"}}
                    className="mt-4 px-4 py-2 self-end text-white rounded hover:bg-blue-600 transition-colors"
                >
                    Close
                </button>
            </Alert>
        </div>
    );
};

export default CustomAlert;