import React from "react";
import "./Hamburger.css";
import {Link} from "react-router-dom";
import {setGlobalState} from "../../App";

const Hamburger = (props) => {

    const [isActive, setIsActive] = React.useState(false);

    return (<>
            <div className="hamburger-menu" onClick={() => setIsActive(!isActive)}>
                <div className={"line" + (isActive ? " active-1" : "")}/>
                <div className={"line" + (isActive ? " active-2" : "")}/>
                <div className={"line" + (isActive ? " active-3" : "")}/>
            </div>
            {isActive && (
                <div className={"menu-content "}>
                    {props.contents.map((content) => (
                        <Link to={content['href']} key={content['id']} className="menu-item text-cyan-16px"
                              onClick={() => {
                                  if (content['name'] === 'Logout') {
                                      setGlobalState('loggedIn', false)
                                      setIsActive(!isActive)
                                      setGlobalState("nameUser", "")
                                      setGlobalState("IdUser", "")
                                      localStorage.removeItem('sessionData')

                                      setGlobalState("token", "")
                                      setGlobalState("surnameUser", "")
                                      window.location.reload()
                                  } else
                                      setIsActive(!isActive)
                              }}
                        >{content['name']}
                        </Link>
                    ))}
                </div>
            )

            }
        </>

    )
}

export default Hamburger;