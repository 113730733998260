import React from 'react'
import '../Marketplace/Marketplace.css'
import './ActivePortfolio.css'
import Modal from "../Modal/Index.jsx";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import {useGlobalState} from "../../App";
import ModalQueue from "../ModalQueue/Index.jsx";
import '../ActivePortfolio/ActivePortfolio.css'
import EditActivePortfolio from "../EditActivePortfolio/Index.jsx";
import {sendSqs} from "../../services/api";



const ConnectedPortfolio = (props) => {
    const [isActive, setIsActive] = React.useState(false)
    const [isDeletePop, setIsDeletePop] = React.useState(false)
    const [namePortfolio, setNamePortfolio] = React.useState('')
    const id = useGlobalState('IdUser')[0]
    const [isShow, setIsShow] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)




    const getSumCut = (exchange) => {
        return props.activePortfolio[exchange].reduce((sum, cut) => sum + parseFloat(cut['portfolio_cut']), 0)
    }

    const deleteConnection = async () => {
        let params = {
            MessageAttributes: {
                "id_user": {
                    DataType: "String",
                    StringValue: id.toString()
                },
                "exchange": {
                    DataType: "String",
                    StringValue: props.exchange
                },
                "portfolio": {
                    DataType: "String",
                    StringValue: namePortfolio
                }
            },
            MessageBody: id + ": user add portfolio",
            QueueUrl: "delete_active_portfolio"
        }
        sendSqs(params.MessageAttributes, params.MessageBody, params.QueueUrl).then(res => {
            if(res.data.send){
                setIsShow(true)
                localStorage.setItem('disconnected_' + props.exchange + '_' + namePortfolio, namePortfolio)
                props.onLocalStorageUpdate()
            }else{
                console.log("Error");

            }
        })

    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: props.disabled && '#e0e0e060'}}>
            <div className="portfolio-connected text-black-16px">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span>{props.exchange}</span>
                </div>
                <div style={{display: 'flex', columnGap: '.5em', alignItems: 'center',flexWrap:'wrap',rowGap:'.5em'}}>
                    {props.disabled ? <span className="text-black-14px">Deleting connected portfolios</span>
                        :
                        <span
                            className="text-black-14px"
                            style={{minWidth: '8em'}}>{getSumCut(props.exchange) >= 0.98 ? 'All funds managed' : 'Available funds:  ≈' + parseInt((1 - getSumCut(props.exchange)) * 100) + '%'}</span>
                    }
                    <button className="text-white-16px edit-pen-button" onClick={() => {
                        setIsEdit(true)
                    }} disabled={ props.editedPort.some(obj=> obj.ex === props.exchange)}><img className="edit-img" src="./img/edit.png"/></button>
                    <button className="btn-connect text-white-16px" style={{fontSize: '14px'}}
                            disabled={props.disabled}
                            onClick={() => {
                                setIsActive(!isActive)
                            }}>{isActive ? 'CLOSE' : 'OPEN'}
                    </button>
                </div>
            </div>
            {isActive &&
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'auto'
                }}>
                    {props.obj.map((port, index) =>
                        <div key={index} className="portfolio-connected text-black-16px"
                             style={{
                                 justifyContent: 'center',
                                 backgroundColor: (props.disconnectedPort.some(obj => obj.ex === props.exchange && obj.value === port['portfolio']) ||
                                     props.editedPort.some(obj=> obj.ex === props.exchange && obj.port === port['portfolio'])) && '#e0e0e060'
                             }}>
                            <div className='info-connected-portfolio'>
                                <div style={{display: 'flex', columnGap: '.5em'}}>
                                    <span style={{fontWeight: 700, letterSpacing: 0.03 + 'em'}}>Portfolio:</span>
                                    <span>{port['portfolio'].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
                                </div>
                                <div style={{display: 'flex', columnGap: '.5em'}}>
                                    <span style={{fontWeight: 700, letterSpacing: 0.03 + 'em',}}>Allocation (%):</span>
                                    <span>{(parseFloat(port['portfolio_cut']) * 100).toFixed(1) + '%'}</span>
                                </div>
                            </div>
                            <button className="delete-button text-white-16px"
                                    style={{fontSize: '12px', padding: ' 0.8rem 1.2rem'}}
                                    disabled={props.disconnectedPort.some(obj => obj.ex === props.exchange && obj.value === port['portfolio']) || props.editedPort.some(obj=> obj.ex === props.exchange && obj.port === port['portfolio'])}
                                    onClick={() => {
                                        setNamePortfolio(port['portfolio'])
                                        setIsDeletePop(true)
                                    }}>DISCONNECT
                            </button>
                            {isDeletePop &&
                                <Modal content={"This portfolio will be disconnected, do you want to continue ?"}
                                       setPop={setIsDeletePop} action={deleteConnection} notClose={true}/>}
                            {isShow && <ModalQueue setPop={setIsShow}/>}
                        </div>
                    )}
                </div>}
            {isEdit && <EditActivePortfolio setPop={setIsEdit} exchange={props.exchange} exchangePortfolios={props.obj} onLocalStorageUpdate={props.onLocalStorageUpdate}/>}
        </div>
    )
}

export default ConnectedPortfolio