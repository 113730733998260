import React from 'react'

import Title from "../Title/Index.jsx";
import ModalQueue from "../ModalQueue/Index.jsx";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import Modal from "../Modal/Index.jsx";
import {useGlobalState} from "../../App";
import {getStatusApiKeyInvestor, sendSqs} from "../../services/api";

const EditApiKeyModal = (props) => {
    const [newTradingPass, setNewTradingPass] = React.useState('')
    const [newPublicKey, setNewPublicKey] = React.useState('')
    const [newPrivateKey, setNewPrivateKey] = React.useState('')
    const user = useGlobalState('username')[0];
    const id = useGlobalState('IdUser')[0]
    const [isPop, setIsPop] = React.useState(false)
    const [isShow, setIsShow] = React.useState(false)
    const [err, setErr] = React.useState(" ")
    const [msg, setMsg] = React.useState(" ")


    const editApi = async () => {

        let params = {
            MessageAttributes: {
                "username": {
                    DataType: "String",
                    StringValue: user
                },
                "id": {
                    DataType: "String",
                    StringValue: id.toString()
                },
                "publicKey": {
                    DataType: "String",
                    StringValue: newPublicKey.trim()
                },
                "privateKey": {
                    DataType: "String",
                    StringValue: newPrivateKey.trim()
                },
                "tradingPass": {
                    DataType: "String",
                    StringValue: newTradingPass === '' ? ' ' : newTradingPass.trim()
                },
                "exchange": {
                    DataType: "String",
                    StringValue: props.exchange
                }
            },
            MessageBody: " edit api key ",
            QueueUrl: "edit_api_key_investor"
        }
        sendSqs(params.MessageAttributes, params.MessageBody, params.QueueUrl).then(res => {
            if (res.data.send) {
                setIsShow(true)
                localStorage.setItem('updated_' + props.exchange, props.exchange)
                props.onLocalStorageUpdate()
            } else {
                console.log("Error");

            }
        })

    }

    const validateApi = () => {
        if (newPublicKey && newPrivateKey) {
            setErr("")
            setMsg('Loading, please wait.')
            getStatusApiKeyInvestor(newPublicKey, newPrivateKey, props.exchange, newTradingPass, id).then(async (response) => {
                if (response.data.isActive !== "API aren't correct") {
                    setIsPop(true)
                    setMsg('')
                } else setErr("API aren't correct")
            })

        } else {
            setErr("Data are missing!")
            setMsg('')
        }
    }

    return (<div className="background-add-modal">
        <div className="container-add-modal">
            <Title Title1="Edit your" Title2="Api Key"/>
            <span className="text-black-16px" style={{opacity: .9, letterSpacing: 0.02 + 'rem'}}>
                    {`Insert new api key for ${props.exchange}`}
                </span>
            <div className="input-group">
                <label className="text-black-16px">Public Key</label>
                <input
                    className="input-data text-black-16px"
                    id="PublicKeyInput"
                    name="PublicKeyInput"
                    placeholder="Enter Public Key"
                    defaultValue={newPublicKey}
                    onChange={(event) => setNewPublicKey(event.target.value)}
                />
            </div>
            <div className="input-group">
                <label className="text-black-16px">Private Key</label>
                <input
                    className="input-data text-black-16px"
                    id="PrivateKeyInput"
                    name="PrivateKeyInput"
                    placeholder="Enter Private Key"
                    defaultValue={newPrivateKey}
                    onChange={(event) => setNewPrivateKey(event.target.value)}
                />
            </div>
            {(props.exchange === 'kucoin' || props.exchange === 'kucoinfutures') &&
                <div className="input-group">
                    <label className="text-black-16px">Passphrase</label>
                    <input

                        className="input-data text-black-16px"
                        id="TradingPasswordInput"
                        name="TradingPasswordInput"
                        placeholder="Enter Passphrase"
                        defaultValue={newTradingPass}

                        onChange={(event) => setNewTradingPass(event.target.value)}
                    />
                </div>}
            <small className="text-danger ">
                {err}
            </small>
            <small className="text-black-14px">
                {msg}
            </small>
            <div className="row-modal">
                <button className="back-button text-white-16px"
                        disabled={msg === 'Loading, please wait.' ? true : false}
                        onClick={() => props.setPop(false)}>Back
                </button>
                <button className="next-button text-white-16px"
                        disabled={msg === 'Loading, please wait.' ? true : false}
                        onClick={() => validateApi()}>Save
                </button>
            </div>
            {isShow && <ModalQueue setPop={setIsShow} action={props.setPop}/>}
        </div>
        {isPop && <Modal content={"If you continue all data will be overwritten, do you want to continue ?"}
                         setPop={setIsPop} action={editApi} notClose={true}/>
        }
    </div>)
}

export default EditApiKeyModal