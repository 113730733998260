import React, { useState } from 'react';
import Title from "../Title/Index.jsx";
import {useGlobalState} from "../../App";
import {sendHelpTicket} from "../../services/api";

const HelpForm = () => {
    const [object, setObject] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const emailUser = useGlobalState('emailUser')[0]
    const username = useGlobalState('username')[0]
    const isTrader = useGlobalState('isTrader')[0]

    const handleSubmit = (event) => {
        setError('')
        setMsg('')
        event.preventDefault();

        if (!object || !description) {
            setError('Please fill in all the mandatory fields.');
            return;
        }

        sendHelpTicket(username, emailUser, object, description, isTrader).then(response => {
            if (response.data.send) {
                setMsg('Ticket sent successfully');
            }else {
                setError('Something went wrong. Please try again later.');
            }
        })

        setObject('');
        setDescription('');
    };

    return (
        <div className='key-tab'>
            <Title Title1="Do you need help?" Title2="Send a ticket"/>
            <form className="help-form" onSubmit={handleSubmit}>
                <div style={{display:'flex',rowGap:'1em',flexDirection:'column',width:'100%'}}>
                    <label className="text-black-16px" htmlFor="object">Object*:</label>
                    <input
                        style={{height: '2.5em',width:'100%',maxWidth: '25em'}}
                        className='input-data text-black-16px'
                        type="text"
                        id="object"
                        value={object}
                        placeholder='Insert the object of your ticket'
                        onChange={(event) => setObject(event.target.value)}
                    />
                </div>

                <div style={{display:'flex',rowGap:'1em',flexDirection:'column',width:'100%'}}>
                    <label className="text-black-16px" htmlFor="description">Problem Description*:</label>
                    <textarea
                        style={{width:'100%',maxWidth: '25em',minHeight: '7em',resize: 'vertical'}}
                        className='input-data text-black-16px'
                        id="description"
                        placeholder='Describe your problem here'
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </div>

                <button className='btn-connect text-white-16px' type="submit">Submit</button>
            </form>
            {error && <p className="text-danger">{error}</p>}
            {msg && <p className="text-black-14px">{msg}</p>}
        </div>

    );
};

export default HelpForm;
