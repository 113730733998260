import React from "react"
import "./OpenPosition.css"

const Movement = (props) => {


    return (
        <div className="movement text-black-16px">
            <span style={{minWidth: 2 + 'rem'}}>{props.i}</span>
            <span style={{minWidth: 15 + 'rem', overflowWrap: "anywhere"}}>{props.exchange}</span>
            <span style={{minWidth: 10 + 'rem'}}>{props.pair}</span>
            <span style={{minWidth: 10 + 'rem'}}></span>
            <span style={{color: props.amount > 0 ? "#73CE62FF" : "#ff5858",minWidth: '4rem'}}>{(props.amount > 0 ? 'LONG ' : 'SHORT ') + props.amount}</span>
        </div>
    )
}

export default Movement;