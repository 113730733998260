import React from "react";
import "./Title.css";

const Title = (props) => {
    return(
        <div className="title-tab">
            <div className="tag-tab"/>
            <div style={{display: "flex", flexDirection: "column"}}>
                <span className="text-black-14px" >{props.Title1}</span>
                <span className="text-black-21px" style={{marginLeft: 1 + 'rem'}}>{props.Title2}</span>
            </div>

        </div>
    );
}

export default Title;