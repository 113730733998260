import * as React from "react";
import { FaCheckCircle, FaMinusCircle, FaExchangeAlt, FaChartLine, FaBolt, FaGift } from "react-icons/fa";
import { SubscriptionCardProps } from "./interfaces.ts";

const serviceMap: any = {
    exchange: { icon: FaExchangeAlt, text: "Exchange" },
    portfolioStrategies: { icon: FaChartLine, text: "Portfolio Strategies" }
    // Aggiungi altri servizi secondo necessità
};

const SubscriptionCard = ({ subscription, onClick, isSubscribed }: SubscriptionCardProps) => {
    //todo: change if is subscribed
    console.log({ isSubscribed });
    const contactUs = () => {
        document.getElementById("contact-mail")?.click();
    };
    return (
        <div
            className="w-full max-w-[440px] h-full bg-white rounded-3xl shadow-2xl hover:shadow-3xl transform hover:-translate-y-2 transition-all duration-500 ease-in-out overflow-hidden relative">
            {/* Blobs di sfondo */}
            <div className="absolute inset-0 overflow-hidden" style={{ borderRadius: 18 }}>
                <div
                    className="absolute -top-20 -left-20 w-64 h-64 bg-sky-200 rounded-full  filter blur-xl opacity-70 animate-blob"></div>
                <div
                    className="absolute top-32 -right-20 w-72 h-72 bg-blue-200 rounded-full  filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                <div
                    className="absolute -bottom-20 left-20 w-56 h-56 bg-indigo-200 rounded-full  filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            </div>

            <div className="relative p-8 flex flex-col h-full z-10">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-3xl font-extrabold text-sky-800 bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-blue-500">
                        {subscription.name}
                    </h2>
                    <span className="text-lg font-semibold text-sky-600 bg-sky-100 px-3 py-1 rounded-full">Pack</span>
                </div>
                <div
                    style={{ background: "#a1dafa" }}
                    className="py-4 bg-gradient-to-r rounded-2xl mb-6 transform hover:scale-105 transition-transform duration-300">
                    <p className="text-center font-bold text-3xl text-sky-800">{subscription.cost}</p>
                </div>

                <p className="mb-2 text-sky-700">{subscription.id === 1 ? "One month Trial" : `Pay every ${subscription.time} months`}</p>
                <p className="mb-6 text-sky-800 font-medium">{subscription.conditions}</p>

                <div className="space-y-4 mb-8">
                    {Object.entries(subscription.details).map(([key, value]) => {
                        const ServiceIcon = serviceMap[key]?.icon || FaBolt;
                        return (
                            <div key={key}
                                 className="flex items-center gap-3 bg-white bg-opacity-60 p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
                                <ServiceIcon className="text-sky-500 text-xl" />
                                <span className="font-medium text-sky-800">{serviceMap[key]?.text || key}:</span>
                                <span className="text-blue-600">{value}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="flex-grow">
                    {subscription.extra && subscription.extra.split(",").map((extra, index) => (
                        <div key={index} className="flex items-center gap-2 mt-2">
                            <FaCheckCircle className="text-green-500 w-5 h-5" />
                            <span className="text-sky-700">{extra.trim()}</span>
                        </div>
                    ))}
                </div>

                {subscription.freeTrial && (
                    <div className="mt-4 bg-green-100 text-green-800 p-3 rounded-lg">
                        <FaGift className="inline-block mr-2" />
                        <span className="font-medium">Free Trial: {subscription.freeTrial}</span>
                    </div>
                )}

                <button
                    onClick={subscription.cost==="Contact us Today"?contactUs:onClick}
                    style={{ background: isSubscribed ? "#4CAF50" : "#4e8098" }}
                    className={`mt-6 w-full p-4 rounded-2xl text-white text-lg font-semibold shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50 flex items-center justify-center gap-2 ${isSubscribed ? "cursor-default" : ""}`}
                    disabled={isSubscribed}
                >
                    <FaGift className="text-xl" />
                    {isSubscribed ? "Subscribed" : "Subscribe Now"}
                </button>
            </div>
        </div>
    );
};

export default SubscriptionCard;