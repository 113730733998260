import React, {useState} from "react";
import Select from 'react-select'
import "./HeaderPlatform.css";
import {useGlobalState} from "../../App";
import Hamburger from "../Hamburger/Index.jsx";
import CheckOut from "../CheckOut";

const HeaderPlatform = () => {

    const username = useGlobalState('username')[0]
    const isTrader = useGlobalState('isTrader')[0];
    const [contents, setContents] = React.useState([{
        id: 1,
        name: "Dashboard",
        href: "/dashboard_" + (isTrader ? "trader" : "investor") + "/user=" + username
    },])

    React.useEffect(() => {
        if (isTrader) {
            setContents([...contents, {
                id: 3,
                name: "Live Trading",
                href: "/dashboard_trader/live_trading"
            }])
        }else{
            setContents([...contents, {
                id: 3,
                name: "Payements",
                href: "/dashboard_investor/payments"
            }])
        }
        setContents([...contents, {
            id: 4,
            name: "Settings",
            href: "/dashboard_" + (isTrader ? "trader" : "investor") + "/settings"
        }, {
            id: 5,
            name: "Logout",
            href: ""
        }])

    }, [])

    return (<>
        <div className="header-platform">
            <span className="text-black-24px">{'Welcome, ' + username}</span>

            <div>
                <Hamburger contents={contents}/>
            </div>
        </div>

    </>);
}

export default HeaderPlatform;
