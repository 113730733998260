import React, { useEffect, useState } from "react";
import "./Marketplace.css";
import Title from "../Title/Index.jsx";
import Axios from "axios";
import { setGlobalState, useGlobalState } from "../../App";
import AddActivePortfolio from "../AddActivePortfolio/Index.jsx";
import { useNavigate } from "react-router-dom";
import { BASE_URL, getAllPortfolios, getApiKeyInvestor } from "../../services/api";
import axios from "axios";

const Marketplace = (props) => {

    const [allPortfolioList, setAllPortfolioList] = React.useState([]);
    const [isActive, setIsActive] = React.useState(false);
    const token = useGlobalState("token")[0];
    const navigate = useNavigate();
    const apiKeyInvestor = useGlobalState("apiKeyInvestor")[0];
    const user = useGlobalState("username")[0];
    console.log(apiKeyInvestor);

    const [namePortfolio, setNamePortfolio] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [showInfo, setShowInfo] = React.useState(-1);

    async function getApiKeys() {
        await getApiKeyInvestor(user.trim()).then((response) => {
            if (response.data.length > 0) {
                setGlobalState("apiKeyInvestor", [...response.data]);
            }
        });
    }

    useEffect(() => {
        if (!apiKeyInvestor?.length)
            getApiKeys();
    }, [apiKeyInvestor]);

    const COLOR_MAP = {
        low: "#0bc40b",
        mid: "#ffc800",
        high: "#ff5a5a"
    };


    React.useEffect(() => {
        setIsLoading(true);
        getAllPortfolios().then((response) => {
            if (response.data.err) console.log("Err");
            else {
                setAllPortfolioList(response.data);
                setIsLoading(false);
            }
        });
    }, []);

    const color = (tag) => COLOR_MAP[tag];

    return (
        <div className="tab-open-position">
            <Title Title1="Check out the" Title2="Marketplace" />
            {apiKeyInvestor.length > 0 && apiKeyInvestor[0].exchange ?
                <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
                    <div style={{ width: "100%", padding: "1.5em .5em" }}>
                        <span className="text-black-16px">You can choose to connect one of your exchange to our portfolios</span>
                    </div>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <img style={{ width: "4em" }} src="./img/loading_big.gif" /></div> :
                        <div className="portfolio-container">
                            {allPortfolioList.map((obj, index) => (
                                <div key={index} className="portfolio-marketplace text-black-16px">
                                    <div className="info-portfolio"
                                         style={{ display: showInfo === index ? "none" : "flex" }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <span className="text-black-14px"
                                                  style={{ fontWeight: "700" }}>Portfolio</span>
                                            {obj["info"] && <button className="info-button"
                                                                    onClick={() => obj["info"].length > 0 && setShowInfo(index)}>Info</button>}
                                        </div>
                                        <span>{obj["portfolio"].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
                                        <span className="text-black-14px" style={{ fontWeight: "700" }}>Trader</span>
                                        <span>{obj["owner"].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
                                        <span className="text-black-14px"
                                              style={{ fontWeight: "700", alignSelf: "end" }}>Aggressive:</span>
                                        <span style={{
                                            display: "flex",
                                            columnGap: ".5em",
                                            alignSelf: "end",
                                            alignItems: "center"
                                        }}>{obj["risk"]?.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ") || ""}
                                            <div className="risk-tag"
                                                 style={{ backgroundColor: color(obj["risk"]) }} /></span>
                                    </div>
                                    <div className="info-portfolio"
                                         style={{ display: showInfo === index ? "flex" : "none" }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <span className="text-black-14px"
                                                  style={{ fontWeight: "700" }}>{"Info " + obj["portfolio"].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
                                            <button className="info-button-close"
                                                    onClick={() => obj["info"].length > 0 && setShowInfo(-1)}><img
                                                style={{ width: "14px" }} src="./img/close.png" /></button>
                                        </div>
                                        <span className="info-text">{obj["info"]}</span>
                                    </div>
                                    <button className="btn-connect text-white-16px" style={{ fontSize: "14px" }}
                                            onClick={() => {
                                                setIsActive(true);
                                                setNamePortfolio(obj["portfolio"]);
                                            }}>CONNECT
                                    </button>
                                </div>
                            ))}
                        </div>}
                </div> :
                (
                    <>
                        <span className="text-black-16px" style={{ opacity: ".8" }}>Before connecting a portfolio you need to register an API key of an exchange</span>
                        <button
                            className="continue-button text-white-16px"
                            style={{ color: "white", width: "15em", border: "none", alignSelf: "center" }}
                            onClick={() => navigate("/dashboard_investor/settings", { replace: true })}
                        >
                            REGISTER API KEY
                        </button>
                    </>
                )

            }
            {isActive && (
                <AddActivePortfolio
                    setPop={setIsActive}
                    api={apiKeyInvestor}
                    activePortfolio={props.activePortfolio}
                    onLocalStorageUpdate={props.onLocalStorageUpdate}
                    connectedPort={props.connectedPort}
                    editedPort={props.editedPort}
                    namePortfolio={namePortfolio}
                />
            )}
        </div>
    );
};

export default Marketplace;