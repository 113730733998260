import React, { useEffect } from "react";
import "./Menu.css";
import NavBar from "./NavBar.jsx";
import {setGlobalState, useGlobalState} from "../../App";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {logout} from "../../services/auth";


const Menu = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const user = useGlobalState("username")[0];
    const handleLogout = async () => {
        await logout()
        navigate('/')

    }

    useEffect(() => {
        console.log(location)
        if(location.pathname === "/dashboard_investor/"||location.pathname==="/dashboard_investor") {
            if(user) {
                navigate("/dashboard_investor/user=" + user, {replace: true})
            }
        }
    }, [user,location]);

    return (<>
        <div className="menu">
            <div style={{display: "flex", flexDirection: "column", rowGap: 5 + "rem"}}>
                <div className="logo">
                    <img src={"./img/LogoTextWhite.png"} className="menu-logo" alt="logo"/>
                </div>
                <NavBar/>
            </div>
            <div className="logout text-white-16px" onClick={handleLogout}>LogOut</div>
        </div>
        <Outlet/>
    </>);
}

export default Menu;
