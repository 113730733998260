import React from 'react'
import '../ActivePortfolio/ActivePortfolio.css'
import Title from "../Title/Index.jsx";
import Axios from "axios";
import {useGlobalState} from "../../App";
import ConnectedPortfolio from "./ConnectedPortfolio.jsx";


const ActivePortfolio = (props) => {

    const [activePortfolio, setActivePortfolio] = React.useState(null)
    const supportedExchanges = useGlobalState('supportedExchanges')[0]
    const [isLoading, setIsLoading] = React.useState(false)

    const [disabledExchange, setDisabledExchange] = React.useState(supportedExchanges.filter(obj => {
        return localStorage.getItem('removed_' + obj) === obj
    }));
    const [connectedPort, setConnectedPort] = React.useState(Object.keys(localStorage)
        .filter(key => key.startsWith("connected_"))
        .map(key => {
            return {
                ex: key.split("_")[1],
                value: localStorage.getItem(key)
            }
        }))



    const defaultActivePortfolio = () => {
        setIsLoading(true)
        const defaultPortfolio = props.activePortfolio.reduce((acc, portfolio) => {
            const {exchange} = portfolio;
            if (!acc[exchange]) {
                acc[exchange] = [];
            }
            acc[exchange].push(portfolio);
            return acc;
        }, {})
        setActivePortfolio({...defaultPortfolio})
        setIsLoading(false)
    }

    React.useEffect(() => {
        defaultActivePortfolio()
    }, [])

    return (
        <div className="tab-open-position">
            <Title Title1="Take a look at your" Title2="Connected Portfolio"/>
            <div style={{overflow: 'auto'}}>
                <div className="subtitle-active-portfolio">
                    <span style={{minWidth: '8rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>Group by Exchange</span>
                    <span style={{minWidth: '8rem', fontWeight: 600, letterSpacing: 0.03 + 'em'}}>(up to 98% of the funds)</span>
                </div>
                {isLoading ? <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}><img style={{width:'4em'}} src='./img/loading_big.gif'/></div> :<div className="active-portfolio-container">
                    {activePortfolio &&
                        Object.entries(activePortfolio).map(([exchange, obj], index) => {
                            return (
                                <ConnectedPortfolio key={index} onLocalStorageUpdate={props.onLocalStorageUpdate}
                                                    disconnectedPort={props.disconnectedPort}
                                                    editedPort={props.editedPort}
                                                    disabled={disabledExchange.includes(exchange)}
                                                    exchange={exchange} obj={obj} activePortfolio={activePortfolio}/>
                            )
                        })}
                    {connectedPort.length > 0 &&
                        <span className="text-black-14px" style={{textAlign: 'center'}}>Please wait, we are connecting the portfolio ...</span>}
                </div>}
            </div>
        </div>
    )
}

export default ActivePortfolio