import React from 'react'
import './AddActivePortfolio.css'
import Title from "../Title/Index.jsx";
import {setGlobalState, useGlobalState} from "../../App";
import Dropdown from "../Dropdown/Index.jsx";
import Axios from "axios";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import ModalQueue from "../ModalQueue/Index.jsx";
import {sendSqs} from "../../services/api";

const AddActivePortfolio = (props) => {

    const [selectedExchange, setSelectedExchange] = React.useState('Select an exchange')
    const [portfolioCut, setPortfolioCut] = React.useState()
    const [exchanges, setExchanges] = React.useState([])
    const [disabled, setDisabled] = React.useState(false)
    const [err, setErr] = React.useState('')
    const id = useGlobalState('IdUser')[0]
    const [isShow, setIsShow] = React.useState(false)
    const supportedExchanges = useGlobalState('supportedExchanges')[0]



    const getLeftSum = (exchange) => {
        return exchanges.reduce((total, obj) => {
            if (obj.name === exchange) {
                return ((1 - obj['cut']) * 100);
            }
            return total;
        }, 0);
    }
    const submit = async () => {
        if (portfolioCut && selectedExchange !== 'Select an exchange') {
            const totCut = (parseFloat(portfolioCut) + (100 - getLeftSum(selectedExchange)))
            if (totCut / 100 > 1 || parseFloat(portfolioCut) < 1)
                setErr('The entered value must be between 1% and ' + getLeftSum(selectedExchange).toFixed(1) + '%')
            else {
                setErr('')
                const date = new Date(Date.now())
                const maxTotCut = 98
                let params = {
                    MessageAttributes: {
                        "id_user": {
                            DataType: "String",
                            StringValue: id.toString()
                        },
                        "exchange": {
                            DataType: "String",
                            StringValue: selectedExchange
                        },
                        "portfolio": {
                            DataType: "String",
                            StringValue: props.namePortfolio
                        },
                        "portfolio_cut": {
                            DataType: "String",
                            StringValue: ((totCut >= 99) ? (parseFloat(portfolioCut) + (maxTotCut - (100 - getLeftSum(selectedExchange)) - parseFloat(portfolioCut))) / 100 : (portfolioCut / 100)).toString()
                        },
                        "timestamp": {
                            DataType: "String",
                            StringValue: date.toISOString().slice(0, 19).replace('T', ' ')
                        }
                    },
                    MessageBody: id + ": user add portfolio",
                    QueueUrl: "add_active_portfolio"
                }
                sendSqs(params.MessageAttributes, params.MessageBody, params.QueueUrl).then(res => {
                    if (res.data.send){
                        setIsShow(true)
                        localStorage.setItem('connected_' + selectedExchange + '_' + props.namePortfolio, props.namePortfolio)
                        props.onLocalStorageUpdate()
                    }else{
                        console.log("Error");

                    }
                })
                        }
        } else {
            setErr('Missing data')
        }
    }

    React.useEffect(() => {
        let activePortfolioExchangeCut = []
        let exchangeCutTotals
        if (props.activePortfolio.length > 0) {
            activePortfolioExchangeCut = props.activePortfolio.map(obj => ({
                exchange: obj.exchange,
                cut: parseFloat(obj['portfolio_cut'])
            }))
            exchangeCutTotals = activePortfolioExchangeCut.reduce((totals, obj) => {
                if (!totals[obj.exchange]) {
                    totals[obj.exchange] = obj['cut']
                } else {
                    totals[obj.exchange] += obj['cut']
                }
                return totals
            }, {})
        }

        const disabledExchange = supportedExchanges.filter(obj => {
            return localStorage.getItem('removed_' + obj) === obj
        });
        const updatedApi = supportedExchanges.filter(obj => {
            return localStorage.getItem('updated_' + obj) === obj
        })

        const apiToConnect = props.activePortfolio.length > 0 ? props.api
                .filter(obj => obj.is_active === 1 && (!disabledExchange.length || !disabledExchange.includes(obj.exchange)) && (!props.connectedPort.length || !props.connectedPort.some(port => port.ex === obj.exchange)) && (!updatedApi.length || !updatedApi.includes(obj.exchange))&& (!props.editedPort.length || !props.editedPort.some(port => port.ex === obj.exchange)))
                .map(obj => ({
                    name: obj.exchange,
                    cut: !Object.keys(exchangeCutTotals).includes(obj.exchange) ? 0 : exchangeCutTotals[obj.exchange]
                }))
                .filter(api => (!Object.keys(exchangeCutTotals).includes(api.name)) || (exchangeCutTotals[api.name] <= 0.98 && props.activePortfolio.every(portfolio => api.name !== portfolio.exchange || props.namePortfolio !== portfolio.portfolio))) :
            props.api
                .filter(obj => obj.is_active === 1 && (!disabledExchange.length || !disabledExchange.includes(obj.exchange)) && (!props.connectedPort.length || !props.connectedPort.some(port => port.ex === obj.exchange)) && (!updatedApi.length || !updatedApi.includes(obj.exchange)) && (!props.editedPort.length || !props.editedPort.some(port => port.ex === obj.exchange)))
                .map(obj => ({
                    name: obj.exchange,
                    cut: 0
                }))
        if (apiToConnect.length > 0)
            setExchanges(apiToConnect)
        else {
            setDisabled(true)
            setExchanges([{name: "You can't connect any wallet to this portfolio"}])
        }

    }, [])


    return (

        <div className="background-add-modal">
            <div className="container-add-modal" style={{maxWidth: '37em'}}>
                <Title Title1="Connect to "
                       Title2={props.namePortfolio.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}/>
                <span className="text-black-16px" style={{opacity: .9, letterSpacing: 0.02 + 'rem'}}>
                    Choose an exchange and insert a percentage allocation of your funds.
                </span>
                <Dropdown selected={selectedExchange}
                          setSelected={setSelectedExchange} contents={exchanges}
                          padding={1.2} disabled={disabled}/>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: '.3em'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <label className="text-black-16px">Percentage of funds (%):</label>
                        {selectedExchange !== 'Select an exchange' &&
                            <span
                                className="text-black-14px">{'Available funds: ' + getLeftSum(selectedExchange).toFixed(1) + '%'}</span>}
                    </div>
                    <input
                        type="number"
                        className="input-data text-black-16px"
                        id="PortfolioCutInput"
                        name="PortfolioCutInput"
                        placeholder="Insert % from 1 to 100"
                        defaultValue={portfolioCut}
                        onChange={(event) => setPortfolioCut(event.target.value)}
                    />
                </div>
                <small className="text-danger">{err}</small>
                <div className="row-modal">
                    <button className="back-button text-white-16px" onClick={() => props.setPop(false)}>Back</button>
                    <button className="next-button text-white-16px"
                            onClick={() => submit()}>Save
                    </button>
                </div>
            </div>
            {isShow && <ModalQueue setPop={setIsShow} action={props.setPop}/>}
        </div>
    )
}

export default AddActivePortfolio