import React from "react"
import "./AddStrategyModal.css"
import {useGlobalState} from "../../App";

import {updatePortfolioAdd} from "../../services/api.js";

const AddStrategyModal = (props) => {

    const [addableStrat, setAddableStrat] = React.useState([])
    const [composition, setComposition] = React.useState([...props.compositionList])
    const [msg, setMsg] = React.useState()
    let userStrategies = useGlobalState('userStrategies')[0]
    let user = useGlobalState('username')[0]

    const getAddableStrategies = () => {
        //  setAddableStrat( userStrategies.map(obj => obj['strategy']).filter(strat => !props.compositionList.map(obj => obj['strategy']).includes(strat)))
        if (!userStrategies.map(obj => obj['strategy']).filter(strat => !props.compositionList.map(obj => obj['strategy']).includes(strat)).length > 0)
            setMsg("You dont have any strategies to add")
        setAddableStrat(
            userStrategies.map(obj => obj['strategy']).filter(strat => !props.compositionList.map(obj => obj['strategy']).includes(strat)).map(obj => {
                return {strategy: obj, disabled: true, weight: 0.0000, leverage: 1}
            })
        )
    }

    React.useEffect(() => {
            getAddableStrategies()
        }, []
    )

    const handleAdd = (name) => {
        setAddableStrat(
            addableStrat.map((strat) => {
                if (strat['strategy'] === name) return {...strat, disabled: false}
                else return strat
            })
        )
    }

    const handleDelete = (name) => {
        setAddableStrat(
            addableStrat.map((strat) => {
                if (strat['strategy'] === name) return {...strat, disabled: true}
                else return strat
            }))
    }


    const handleValue = (event, name) => {
        setAddableStrat(
            addableStrat.map((size) => {
                if (size['strategy'] === name) return {...size, weight: event.target.value}
                else return size
            })
        )

    }

    const handleLeverage = (event, name) => {
        setAddableStrat(
            addableStrat.map((size) => {
                if (size['strategy'] === name) return {...size, leverage: event.target.value}
                else return size
            })
        )

    }

    const handleValueComp = (event, name) => {
        setComposition(
            composition.map((size) => {
                if (size['strategy'] === name) return {...size, weight: event.target.value}
                else return size
            })
        )

    }

    const save = async () => {
        let sumWeigths = (addableStrat.map(data => !data['disabled'] && parseFloat(data.weight)).reduce((a, b) => a + b)) +
            composition.map(data => !data['disabled'] && parseFloat(data.weight)).reduce((a, b) => a + b)

        if (msg === "You dont have any strategies to add") {
            setMsg("")
            props.setPop(false)
        } else {
            const isChecked = addableStrat.some(c => c.disabled === false)
            if (isChecked) {
                if (sumWeigths === 1) {
                    updatePortfolioAdd(addableStrat, user, props.namePortfolio, composition,props.isLive, props.risk, props.info).then(response => {
                        if (response.data.save) {
                            props.getComposition()
                            props.setIsEditable(false)
                            props.setPop(false)
                        }
                    })
                }

            else
                setMsg("The sum of the weights must be equal 1. Current sum: " + sumWeigths)
            } else {
                setMsg("Choose at least 1 strategy")
            }
        }
    }

    return (
        <div className="background-add-modal">
            <div className="container-add-modal">
                <label className="text-black-16px" style={{
                    fontWeight: 700,
                    paddingBottom: 1.5 + "rem",
                    borderBottom: 'solid ' + 1 + 'px #00000060',

                }}>{"Add strategies to " + props.namePortfolio.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</label>
                <div className="strategies-modal" style={{rowGap: 0}}>
                    <label className="text-black-16px" style={{fontWeight: 600}}>Current portfolio composition:</label>
                    {props.compositionList.map((obj, index) => (
                        <div key={index}
                             style={{
                                 display: 'flex', columnGap: 0.5 + 'rem',
                                 justifyContent: "space-between",
                                 width: 100 + "%",
                                 alignItems: "center",
                                 padding: 0.5 + "rem"
                             }}>
                            <label className="text-black-16px"
                                   style={{
                                       overflowWrap: 'anywhere',
                                       maxWidth: 20 + 'rem'
                                   }}>{obj['strategy'].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</label>

                            <div className="input-portfolio">
                                <label className="text-black-16px">Sizing:</label>
                                <input
                                    className="input-data-portfolio text-black-16px"
                                    type="number"
                                    id="Exist-sizing"
                                    name="sizing"
                                    defaultValue={obj['weight']}
                                    disabled={obj['disabled']}
                                    onChange={(event) => {
                                        handleValueComp(event, obj['strategy'])
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="strategies-modal" style={{borderTop: 'solid ' + 1 + 'px #00000060'}}>
                    {addableStrat.map((strat, index) => (
                        <div key={index} className="strategy-list"
                             style={strat['disabled'] ? {opacity: 0.3} : {opacity: 1}}>
                            <div className="input-modal">
                                <input
                                    type="checkbox"
                                    id={"addStrat" + index}
                                    onChange={() => {
                                        strat['disabled'] ? handleAdd(strat['strategy']) : handleDelete(strat['strategy'])
                                    }}
                                    defaultChecked={false}
                                />
                                <label htmlFor={"addStrat" + index}
                                       className="text-black-16px"
                                       style={{
                                           overflowWrap: 'anywhere',
                                           maxWidth: 20 + 'rem'
                                       }}>{strat['strategy'].split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</label>
                            </div>
                            <div style={{display: 'flex', columnGap: 0.5 + 'rem'}}>
                                <div className="input-portfolio">
                                    <label className="text-black-16px" htmlFor={"addStrat" + index}>Leverage:</label>
                                    <input
                                        className="input-data-portfolio text-black-16px"
                                        type="number"
                                        id="leverage"
                                        name="leverage"
                                        defaultValue={strat['leverage']}
                                        disabled={strat['disabled']}
                                        onChange={(event) => {
                                            handleLeverage(event, strat['strategy'])
                                        }}
                                    />
                                </div>
                                <div className="input-portfolio">
                                    <label className="text-black-16px" htmlFor={"addStrat" + index}>Sizing:</label>
                                    <input
                                        className="input-data-portfolio text-black-16px"
                                        type="number"
                                        id="sizing"
                                        name="sizing"
                                        defaultValue={strat['weight']}
                                        disabled={strat['disabled']}
                                        onChange={(event) => {
                                            handleValue(event, strat['strategy'])
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <small className="text-danger">{msg}</small>
                <div className="row-modal text-white-16px">
                    <div className="cancel-button" onClick={() => props.setPop(false)}>Cancel</div>
                    <div className="continue-button" onClick={() => {
                        save()
                    }}>Continue
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddStrategyModal