import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../services/api";
import RevolutCheckout from "@revolut/checkout";
import { Button } from "@chakra-ui/react";
import * as React from "react";

const RevolutCheckoutComponent = ({ amount, currency, description, setSuccess, userId, subscriptionId }: {
    amount: number,
    currency: string,
    description: string,
    setSuccess: (success: boolean) => void,
    userId: string | number,
    subscriptionId: number
}) => {
    const handlePayment = async () => {
        try {
            const response = await axios.post(BASE_URL + "/create-payment", {
                amount: (amount),
                currency,
                description
            });

            const { id, token } = response.data;
            console.log(id);

            const instance = await RevolutCheckout(token);

            instance.payWithPopup({
                onSuccess() {
                    console.log("Pagamento completato con successo");
                    // TODO: chiamata api
                    createUserSubscription(id);
                    setSuccess(true);
                },
                onError(error) {
                    console.error("Errore durante il pagamento", error);
                    setSuccess(false);
                    // TODO: popup di errore
                },
                onCancel() {
                    console.log("Pagamento annullato dall'utente");
                    // TODO: popup di cancellazione
                }
            });
        } catch (error) {
            console.error("Errore nella creazione dell'ordine", error);
        }
    };

    const createUserSubscription = async (paymentId: string) => {
        try {
            const startDate = new Date();
            const endDate = new Date(startDate);
            endDate.setMonth(endDate.getMonth() + 1); // Assuming 1 month subscription

            await axios.post(`${BASE_URL}/api/user-subscriptions`, {
                user_id: userId,
                subscription_id: subscriptionId,
                start_date: startDate.toISOString().replace( "T", " ").split(".")[0],
                end_date: endDate.toISOString().replace("T", " ").split(".")[0],
                payment_method: "Revolut",
                payment_id: paymentId
            });
        } catch (error) {
            console.error("Error creating user subscription", error);
            alert("Error creating subscription. Please contact support.");
        }
    };

    return (
        <div>
            <Button mb={"-20px"} onClick={handlePayment} color={"white"} bg={"black"}
                    style={{ width: "100%", minHeight: "50px" }}>
               Prepaid / debit / credit card
            </Button>
        </div>
    );
};

export default RevolutCheckoutComponent;