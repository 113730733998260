import React from "react"
import "./SetupLive.css"

const Row = (props) => {

    const handleValue = (event) => {
        const arr = [...props.list];
        arr[props.i] = {value: event.target.value, perc: arr[props.i].perc};
        props.setList(arr);
    }
    const handleQty = (event) => {
        const arr = [...props.list];
        arr[props.i] = {value: arr[props.i].value, perc: event.target.value};
        props.setList(arr);
    }

    return (
        <div className="container-input-setup">
            <div className="input-setup">
                <label className="text-black-14px" style={{opacity:0.9}}>{props.Title + ":"}</label>
                <input
                    className="input-data-setup text-black-16px"
                    type="number"
                    id={"inputValue" + props.i}
                    name="inputValue"
                    value={props.value}
                    onChange={(event) => {
                        handleValue(event)
                    }}
                    disabled={props.isDisabled}
                />
            </div>
            <div className="input-setup">
                <label className="text-black-14px" style={{opacity:0.9}}>Qty (%):</label>
                <input
                    className="input-data-setup text-black-16px"
                    type="number"
                    id={"Qty" + props.i}
                    name="Qty"
                    defaultValue={props.perc}
                    onChange={(event) => {
                        handleQty(event)
                    }}
                    disabled={props.isDisabled}
                />
            </div>

            <button className="remove-row" onClick={() => props.delete(props.i)} disabled={props.isDisabled}/>
        </div>
    )
}

export default Row