import React from "react"
import "./OpenPosition.css"
import Title from "../Title/Index.jsx";
import Movement from "./Movement.jsx";
import Axios from "axios";
import {setGlobalState, useGlobalState} from "../../App";
import {getOpenPositions} from "../../services/api";

const OpenPosition = () => {

    const [openPositionList, setOpenPositionList] = React.useState([])
    const id = useGlobalState('IdUser')[0];

    React.useEffect(() => {
       getOpenPositions(id).then((response) => {
            if (response.data.err) console.log(response.data.err)
            else {
                setOpenPositionList(response.data)
            }
        })

    }, [])

    return (
        <div className="tab-open-position">
            <Title Title1="Take a look at" Title2="Your Open Positions"/>
            <div style={{overflow: 'auto'}}>
                <div className="subtitle-movements">
                    <span style={{minWidth: 2 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>n°</span>
                    <span
                        style={{minWidth: 13 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>Exchange</span>
                    <span style={{minWidth: 9 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>Pair</span>
                    <span style={{minWidth: 10 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}></span>
                    <span style={{minWidth: 3 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>Amount</span>
                </div>
                <div className="movements-list">
                    {openPositionList.length > 0 ? openPositionList.map((obj, index) => (
                        <Movement key={index} i={index + 1} exchange={obj['exchange']} pair={obj['share_name']}
                                  amount={parseFloat(obj['amount']).toFixed(4)} />
                    )) : <span className="text-black-16px" style={{opacity: .8,padding:'1em 2em'}}>You don't have any open positions</span>}
                </div>
            </div>

        </div>
    )
}

export default OpenPosition