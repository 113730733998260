import React, {useEffect} from "react";
import "./PlatformTrader.css";
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import YourStrategy from "../YourStrategy/Index.jsx"
import {useGlobalState} from "../../App";
import {useNavigate} from "react-router-dom";
import YourPortfolio from "../YourPortfolio/Index.jsx";


const PlatformTrader = () => {


    return(
        <div className="background" style={{width: + "px"}}>
            <HeaderPlatform />
            <div className="col-platform">
                <YourStrategy/>
                <YourPortfolio/>
            </div>

        </div>
    );
}

export default PlatformTrader;