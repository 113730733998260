import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import SubscriptionCard from "./SubscriptionCard";
import SubscriptionModal from "./StripeSubscriptionModal";
import { Subscription } from "./interfaces.ts";
import { BASE_URL } from "../../services/api";
import { useGlobalState } from "../../App";

const Subscriptions = () => {
    const [selectedSubscription, setSelectedSubscription] = useState<null | Subscription>(null);
    const [isModalCheckoutOpen, setIsModalCheckoutOpen] = useState(false);
    const [value, setValue] = useState("0");
    const [subscriptions, setSubscriptions] = useState([]);
    const [userPlan, setUserPlan] = useState<null | Subscription | any>(null);
    const userId = useGlobalState("IdUser")[0];
    console.log(userPlan);
    useEffect(() => {
        axios.get(`${BASE_URL}/api/subscriptions`).then((response) => {
            setSubscriptions(response.data);
        });
    }, []);
    useEffect(() => {
        const fetchUserSubscription = async () => {
            try {
                // Assuming you have the user's ID stored somewhere (e.g., in localStorage or in a global state)
                const response = await axios.get(`${BASE_URL}/api/user-subscriptions/${userId}`);
                if (response.data.length > 0) {
                    // Assuming the user has only one active subscription
                    const userSubscription = response.data[0];
                    const subscriptionDetails = subscriptions.find((sub: Subscription) => sub.id === userSubscription.subscription_id);
                    setUserPlan(subscriptionDetails || null);
                }
            } catch (error) {
                console.error("Error fetching user subscription:", error);
            }
        };
        fetchUserSubscription();
    }, [subscriptions]);
    console.log(userPlan);
    return (
        <div
            className="min-h-screen bg-white pt-20 px-4 sm:px-0 lg:px-0 relative overflow-hidden  sm:pl-12">
            {/* Background Blobs */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <div
                    className="absolute -top-40 -left-40 w-80 h-80 bg-sky-200 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div>
                <div
                    className="absolute top-0 -right-20 w-72 h-72 bg-blue-200 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-2000"></div>
                <div
                    className="absolute -bottom-40 left-20 w-96 h-96 bg-indigo-200 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-4000"></div>
            </div>

            <div className="relative z-10 mx-auto">
                <h1 className="text-5xl font-extrabold text-center mb-6 bg-clip-text " style={{ color: "#4e8098" }}>
                    SUBSCRIBE NOW
                </h1>
                <p className="text-center text-xl text-sky-800 mb-16 max-w-3xl mx-auto">
                    Want to improve your trading?<br />
                    Subscribe to our site for advanced signals, real-time analysis and exclusive tools<br />
                    Choose the right plan for you and start earning more money today!
                </p>

                <div className="overflow-x-auto  -mx-4 px-4" style={{paddingBottom:7+"rem"}}>
                    <div className="flex gap-8 justify-center min-w-max">
                        {subscriptions.map((subscription: any) => (
                            (userPlan?.id <= subscription?.id || !userPlan?.id) && (
                                <div
                                    key={subscription.name}
                                    className="w-full sm:w-[440px] transform hover:-translate-y-2 transition-all duration-300 ease-in-out"
                                >
                                    <SubscriptionCard
                                        isSubscribed={userPlan?.id === subscription?.id}
                                        isExpanded={false}
                                        subscription={subscription}
                                        onClick={() => {
                                            setSelectedSubscription(subscription);
                                            setValue(parseFloat(subscription.price?.toString() || "10").toFixed(2));
                                            setIsModalCheckoutOpen(true);
                                        }}
                                    />
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <SubscriptionModal
                userId={userId}
                value={value}
                onClose={() => setIsModalCheckoutOpen(false)}
                open={isModalCheckoutOpen}
                subscription={selectedSubscription || undefined}
            />
            <a href={"mailto:info@AIdvisory.ai"} target={"_blank"} className={"hidden"} id={"contact-mail"} />
        </div>
    );
};

export default Subscriptions;