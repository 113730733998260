import React from "react"
import "./AddApiKey.css"
import Dropdown from "../Dropdown/Index.jsx";
import Title from "../Title/Index.jsx";
import Axios from "axios";
import {useGlobalState} from "../../App";
import {SendMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import ModalQueue from "../ModalQueue/Index.jsx";
import {getStatusApiKeyInvestor, sendSqs} from "../../services/api";
import {renderToPipeableStream} from "react-dom/server";


const AddApiKeyModal = (props) => {


    const [exchangeList, setExchangeList] = React.useState([{
        id: 0,
        name: "Binance",
        value: "binance",
        alert: "Attention: Binance doesn't support futures trading for some country"
    }, {
        id: 1,
        name: "Kucoin",
        value: "kucoin",
        alert: "Attention: Kucoin is for spot only, use KucoinFutures to use futures"
    }, {
        id: 2,
        name: "Bybit",
        value: "bybit",
        alert: ''
    }, {
        id: 3,
        name: "Kucoin Futures",
        value: "kucoinfutures",
        alert: "Attention: KucoinFutures is for futures only, use Kucoin to use spot"
    }])



    const [disabled, setDisabled] = React.useState(false)
    const [selectedExchange, setSelectedExchange] = React.useState("Select an Exchange")
    const [publicKey, setPublicKey] = React.useState("")
    const [privateKey, setPrivateKey] = React.useState("")
    const [tradingPass, setTradingPass] = React.useState("")
    const [err, setErr] = React.useState(" ")
    const [msg, setMsg] = React.useState(" ")
    const user = useGlobalState('username')[0];
    const IdUser = useGlobalState('IdUser')[0];
    const password = useGlobalState('password')[0];
    const email = useGlobalState('emailUser')[0];
    const name = useGlobalState('nameUser')[0];
    const surname = useGlobalState('surnameUser')[0];
    const apiKeyInvestor = useGlobalState('apiKeyInvestor')[0];
    const [isShow, setIsShow] = React.useState(false)
    const supportedExchanges = useGlobalState('supportedExchanges')[0]


    React.useEffect(() => {

        let connectedExchange
        if (apiKeyInvestor) {
            connectedExchange = apiKeyInvestor.map(obj => {
                return obj['exchange']
            });
            if (exchangeList.filter(x => !connectedExchange.includes(x['value'])).length > 0) {
                setExchangeList(exchangeList.filter(x => !connectedExchange.includes(x['value']) && !props.addedApi.includes(x['value'])))
            } else {
                setExchangeList([{name: "Exchange is not available"}])
                setDisabled(true)
            }

        }



    }, [])


    const submit = async () => {
        if ((selectedExchange !== "Select an Exchange") && publicKey  && privateKey) {
            setErr("")
            let exchange = exchangeList.find((element) => {
                return element['name'] === selectedExchange;
            })
            setMsg('Loading, please wait.')
            getStatusApiKeyInvestor(publicKey, privateKey, exchange.value, tradingPass, IdUser).then(async (response) => {
                if (response.data.isActive !== "API aren't correct") {
                    let params = {
                        MessageBody: user + ": add api key ",
                        MessageAttributes: {
                            "id": {
                                DataType: "String",
                                StringValue: IdUser.toString()
                            },
                            "publicKey": {
                                DataType: "String",
                                StringValue: publicKey.trim()
                            },
                            "username": {
                                DataType: "String",
                                StringValue: user
                            },
                            "tradingPass": {
                                DataType: "String",
                                StringValue: tradingPass === '' ? ' ' : tradingPass.trim()
                            },
                            "privateKey": {
                                DataType: "String",
                                StringValue: privateKey.trim()
                            },
                            "exchange": {
                                DataType: "String",
                                StringValue: exchange.value
                            },
                            "password": {
                                DataType: "String",
                                StringValue: password
                            },
                            "info": {
                                DataType: "String",
                                StringValue: email + "-" + name + "-" + surname
                            },
                            "isFirst": {
                                DataType: "String",
                                StringValue: !apiKeyInvestor[0].exchange ? 'true' : 'false'
                            },
                            "isActive": {
                                DataType: "String",
                                StringValue: response.data.isActive.toString()
                            }
                        },
                        QueueUrl: "add_api_key_investor"
                    }
                    sendSqs(params.MessageAttributes, params.MessageBody,params.QueueUrl).then((response) => {
                        if (response.data.send){
                            setMsg('')
                            setIsShow(true)
                            localStorage.setItem('added_' + exchange.value, exchange.value)
                            props.onLocalStorageUpdate()
                        }else{
                            console.log("Error");
                            setMsg('')
                        }

                    })

                } else if(response.data.isActive === "API aren't correct") {
                    setMsg('')
                    setErr("API aren't correct")
                }else{
                    setMsg('')
                    setErr("Something went wrong, please try again")
                }
            })

        } else {
            setErr("Data are missing!")
            setMsg('')
        }
    }

    return (
        <div className="background-add-modal">
            <div className="container-add-modal">
                <Title Title1="Add new" Title2="Api Key"/>
                <span className="text-black-16px" style={{opacity: .9, letterSpacing: 0.02 + 'rem'}}>
                    To start using the Aidvisory services it is necessary to connect the Api Keys provided by one of the supported Exchanges.
                </span>
                <Dropdown selected={selectedExchange}
                          setSelected={setSelectedExchange} contents={exchangeList}
                          padding={1.2} disabled={disabled}/>
                <small className="text-danger" style={{marginTop: '.5em'}}>{exchangeList.map(ex => {
                    if (ex['name'] === selectedExchange)
                        return ex['alert']
                })}</small>
                <div className="input-group">
                    <label className="text-black-16px">Public Key</label>
                    <input
                        className="input-data text-black-16px"
                        id="PublicKeyInput"
                        name="PublicKeyInput"
                        placeholder="Enter Public Key"
                        defaultValue={publicKey}
                        disabled={disabled}
                        onChange={(event) => setPublicKey(event.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="text-black-16px">Private Key</label>
                    <input
                        className="input-data text-black-16px"
                        id="PrivateKeyInput"
                        name="PrivateKeyInput"
                        placeholder="Enter Private Key"
                        defaultValue={privateKey}
                        disabled={disabled}
                        onChange={(event) => setPrivateKey(event.target.value)}
                    />
                </div>
                {(selectedExchange === 'Kucoin' || selectedExchange === 'Kucoin Futures') &&
                    <div className="input-group">
                        <label className="text-black-16px">Passphrase</label>
                        <input
                            className="input-data text-black-16px"
                            id="TradingPasswordInput"
                            name="TradingPasswordInput"
                            placeholder="Enter Passphrase"
                            defaultValue={tradingPass}
                            disabled={disabled}
                            onChange={(event) => setTradingPass(event.target.value)}
                        />
                    </div>}
                <small className="text-danger ">
                    {err}
                </small>
                <small className="text-black-14px">
                    {msg}
                </small>
                <div className="row-modal">
                    <button className="back-button text-white-16px"
                            disabled={msg === 'Loading, please wait.' ? true : false}
                            onClick={() => props.setPop(false)}>Back
                    </button>
                    <button className="next-button text-white-16px"
                            disabled={msg === 'Loading, please wait.' ? true : false}
                            onClick={() => disabled ? props.setPop(false) : submit()}>Save
                    </button>
                </div>
                {isShow && <ModalQueue setPop={setIsShow} action={props.setPop}/>}
            </div>
        </div>
    )
}

export default AddApiKeyModal;