import Web3 from "web3";
import Web3Modal from "web3modal";
import {useEffect, useState} from "react";
import {setGlobalState, useGlobalState} from "../../App";
import {updateStatusPayment} from "../../services/api";


export const Web3Client = () => {
     //for metamask config
    let web3Modal = useGlobalState('modal')[0]

    const [selectedAccount, setSelectedAccount] = useState('');
    let provider;

    useEffect(() => {
        const init = async () => {
            const getProviderOptions = () => {
                return {};
            }

            const modal = new Web3Modal({
                network: "mainnet",
                cacheProvider: true,
                providerOptions: getProviderOptions(),
                theme: 'dark'
            })

            setGlobalState('modal', modal)
            try {
                provider = await modal.connect()
            } catch (e) {
                return setGlobalState('hideConn', true)
            }

            const web3Appo = await new Web3(provider)
            setGlobalState('web3', web3Appo)

            provider.on("accountsChanged", async accounts => {
                setGlobalState('activeAddr', accounts[0])
                setGlobalState('hideConn', true)
                await setSelectedAccount(accounts[0])
            });

            const address = provider.isMetaMask ? provider.selectedAddress : provider.accounts[0]

            setSelectedAccount(address)

            setGlobalState('activeAddr', provider.isMetaMask ? provider.selectedAddress : provider.accounts[0])
        }

        init().then(r => console.log('enter 1'))
    }, []);

    return <div className="d-flex flex-center bgi-size-cover bgi-no-repeat text-white"/>

}

let networks

const ChangeNet = async (web3, net) => {
    console.log('enter 4')
    console.log(net)

    if (!networks)
        networks = {
            LUXOCHAIN: [{
                chainName: 'Luxochain',
                chainId: web3.utils.toHex(110),
                nativeCurrency: {name: 'LUXO', decimals: 18, symbol: 'LUXO'},
                rpcUrls: ['https://lugano.nodes.luxochain.io']
            }],
            POLYGON: [{
                chainName: 'Polygon',
                chainId: web3.utils.toHex(137),
                nativeCurrency: {name: 'MATIC', decimals: 18, symbol: 'MATIC'},
                rpcUrls: ['https://polygon-rpc.com/']
            }]
        }

    await new Promise((resolve, reject) => {
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: networks[net]
        })
            .then((r) => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    });

    window.web3.currentProvider.on("accountsChanged", async accounts => {
        setGlobalState('activeAddr', accounts[0])
    })
}

export const transferERC20 = async (web3, fromAddress, toAddress, amount, id) => {
    const minABI = [
        {
            "constant": false,
            "inputs": [
                {
                    "name": "_to",
                    "type": "address"
                },
                {
                    "name": "_value",
                    "type": "uint256"
                }
            ],
            "name": "transfer",
            "outputs": [
                {
                    "name": "",
                    "type": "bool"
                }
            ],
            "type": "function"
        },// decimals
        {
            "constant": true,
            "inputs": [],
            "name": "decimals",
            "outputs": [
                {
                    "name": "",
                    "type": "uint8"
                }
            ],
            "type": "function"
        }
    ];
    let contract
    // Get Contract instance
    try {
        contract = new web3.eth.Contract(minABI, "0xdAC17F958D2ee523a2206206994597C13D831ec7");
        const decimals = await contract.methods.decimals().call();
    } catch (e) {
        alert('Check your network to Ethereum Mainnet')
    }

    // Calculate token amount (token has decimals)
    // Get the number of decimals first if you don't know it


    const tokenAmount = amount * (10 ** 6)

    const amountHex = web3.utils.toHex(tokenAmount);
    // Call contract function
    return contract.methods.transfer(toAddress, amountHex).send({
        from: fromAddress, gas: 21000,     // Gas limit. The limit could be more for complex transactions
        gasPrice: web3.utils.toWei('50', 'gwei')
    })
        .then((receipt) => {
            console.log('Transaction was successful, here is the receipt: ', receipt);
            updateStatusPayment(id)
        })
        .catch((error) => {
            console.error('An error occurred while executing the transaction: ', error);
        })
}