import React from "react"
import "../Modal/Modal.css"

const ModalQueue = (props) => {
    return (
        <div className="background-modal">
            <div className="container-modal text-black-16px">
                <label style={{fontWeight: 700, paddingBottom: 1 + 'rem', borderBottom: 'solid ' + 1 + 'px' + ' #00000060'}}>Attention</label>
                <label style={{paddingTop: 1 + 'rem', paddingBottom: 1 + 'rem'}}>
                    We are handling your request, it can take up to 1 minute.</label>
                <div className="row-modal text-white-16px">
                    <div className="continue-button" onClick={() => {
                        if (props.action)
                            props.action(false)
                        props.setPop(false)
                    }}>OK, got it</div>
                </div>
            </div>
        </div>
    )
}

export default ModalQueue;