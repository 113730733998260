import React, { useEffect } from "react";
import "./LandingPage.css";
import Header from "../Header/Index.tsx";
import Hero from "../Hero/Index.jsx";
import AboutUs from "../AboutUs/Index.jsx";
import Products from "../Products/Index.jsx";
import Analytics from "../Analytics/Index.jsx";
import Footer from "../Footer/Index.jsx";
import { setGlobalState, useGlobalState } from "../../App";
import { transferERC20, Web3Client } from "../Web3/Web3Client";
import { getDataLanding } from "../../services/api";
import Subscriptions from "../Subscriptions/index.tsx";

const LandingPage = () => (
	<div className="landing-page">
		<Header />
		{/*<div className="news text-black-16px">*/}
		{/*	<span className="alert-title">We are raising fund!</span>*/}
		{/*	<span>*/}
		{/*		AIdvisory has started its first round of fundraising amounting*/}
		{/*		to $250,000 needed to further develop its business.*/}
		{/*	</span>*/}
		{/*	<span>*/}
		{/*		The company has issued 5,000,000 shares and the pre-money is*/}
		{/*		$10,000,000, cost per share: $2/share*/}
		{/*	</span>*/}
		{/*	<span>*/}
		{/*		Below is the link to the internal collection platform (it*/}
		{/*		accepts bank transfers, credit cards and paypal):*/}
		{/*	</span>*/}
		{/*	<a*/}
		{/*		className="news-btn"*/}
		{/*		href="https://metaglobe.finance/fundme/campaign/10/optimise-crypto-trading-w-ai-based-algorithms"*/}
		{/*	>*/}
		{/*		Visit the fund campaign*/}
		{/*	</a>*/}
		{/*</div>*/}
		<div className={"h-32"} />
		<Hero {...HeroData} />
		<AboutUs {...AboutUsData} />
		<Products {...ProductsData} />
		 {/*<Analytics {...AnalyticsData}/>*/}
		<Subscriptions bg="#00abfd14" />
		<Footer />
	</div>
);

export default LandingPage;

const HeroData = {
	title1: "This is",
	title2: "AIdvisory",
	descriptionHero:
		"AI-based algorithms for optimised and automatic capital management",
	descriptionTab1:
		"Our products are based on cutting-edge technologies ensuring a reliable and safe service",
	descriptionTab2:
		"Our investment support service fills the knowledge gap for more accessible trading",
	descriptionTab3:
		"We provide you with ad hoc investment strategies made by professional traders on the our platform ",
};

const AboutUsData = {
	title1: "About",
	title2: "Us",
	descriptionAboutUs:
		"We are a team of experts in the fields of information technology and the crypto market. After years of study and hard work, we were able to think and develop an innovative system based on artificial intelligence to manage portfolios. Our system is capable of optimizing and putting into practice the strategies designed by our traders",
	descriptionVision:
		"Make equitable and investing systems accessible to everyone while bringing exponential growth followed by further development which in turn leads to unlimited profitable paths",
	descriptionMission:
		"Our mission is to find the best ways to manage capital in crypto markets. Our A.I. will make our products a class of it's own",
};
const ProductsData = {
	title1: "Our",
	title2: "Products",
	descriptionProducts:
		"Our products are designed for all types of users, both for beginners and experts. We will provide a choice of strategies that can be used. It will also be possible to view the performances and all the data necessary for the user to monitor his investments. Our services are also designed for traders who want to present their strategies. If you think you can contribute please contact us",
	descriptionManaged:
		"The user relies on expert traders in the sector who will take care of choosing a combination of strategies indicated for the size of the portfolio",
	descriptionManual:
		"The experienced user selects the desired combination of strategies. This combination is supervised by our algorithm, which validates the user's choices based on risk profiles",
};
const tab1Data = {
	title1: "Generated",
	title2: "Profit",
	data: "$ 0",
};

const tab2Data = {
	title1: "Managed",
	title2: "Capital",
	data: "$ 0",
};

const tab3Data = {
	title1: "Managed",
	title2: "Wallets",
	data: "0",
};

const AnalyticsData = {
	title1: "Our",
	title2: "Analytics",
	descriptionAnalytics:
		"Hard work and dedication are the two elements that characterize us, which is why we are proud to show our analytics of our services. We will strive to improve our technologies to provide products that are always consistent with the market",
	tab1: tab1Data,
	tab2: tab2Data,
	tab3: tab3Data,
};
