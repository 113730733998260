import './index.css'

function CheckOut(props) {
    return props.trigger && <div className='popup'>
        <div className='popup-inner'>
            <div className='close-btn' style={{alignSelf:"end"}} onClick={() => props.setTrigger(false)}>
                <div className="left-x"/>
                <div className="right-x"/>
            </div>
            <div className='children'>
                {props.children}
            </div>
        </div>
    </div>
}

export default CheckOut