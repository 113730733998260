import React from "react"
import "../Modal/Modal.css"
import {setGlobalState, useGlobalState} from "../../App";
import {transferERC20, Web3Client} from "../Web3/Web3Client";

const ModalPayment = (props) => {
    const hideConn = useGlobalState('hideConn')[0]
    const web3 = useGlobalState('web3')[0]
    const address = useGlobalState('activeAddr')[0]
    const userId = useGlobalState('IdUser')[0]

    console.log(address)
    console.log(props.amount)
    return (
        <div className="background-modal">
            <div className="container-modal text-black-16px">
                <label style={{fontWeight: 700, paddingBottom: 1 + 'rem', borderBottom: 'solid ' + 1 + 'px' + ' #00000060'}}>{props.blacklist ? 'Attention' : 'Payment tab'}</label>
                {props.blacklist && <label style={{paddingTop: 1 + 'rem', paddingBottom: 1 + 'rem'}}>
                    15 days have passed since your payment request was issued, please pay to continue with our
                    services</label>}
                <label style={{paddingTop: 1 + 'rem', paddingBottom: 1 + 'rem'}}>
                    Please choose a payment method:</label>
                <div className="row-modal text-white-16px">
                    <div className='cancel-button' onClick={() => props.setPop(false)}>Cancel</div>
                    <div className="continue-button" onClick={() => {
                        if (props.payCard)
                            props.payCard()
                        props.setPop(false)
                    }}>Pay with Card</div>
                    {address === 'Connect Wallet' ? <div className="continue-button" onClick={() => {
                        if (hideConn)
                            setGlobalState('hideConn', false)
                    }}>Connect Metamask</div>
                    : <div className="continue-button" onClick={() => {
                             transferERC20(web3, address, '0xdD71f9789387A3616D66c4c79c2Ef6851ba3430c', props.amount, userId).then()
                            setGlobalState('hideConn', true)
                            props.setPop(false)
                        }}>Pay with Crypto</div>}
                    {!hideConn && <Web3Client/>}

                </div>
            </div>
        </div>
    )
}

export default ModalPayment;