import React from "react";
import "./YourStrategy.css";
import Title from "../Title/Index.jsx";
import Strategy from "./Strategy.jsx"
import Axios from "axios";
import {setGlobalState, useGlobalState} from "../../App";
import {getNewStrategies} from "../../services/api";


const YourStrategy = () => {

    let user = useGlobalState('username')[0]

    let userStrategies = useGlobalState('userStrategies')[0]

    const getStrategies = () => {
        getNewStrategies(user).then((response) => {
            if (response.data.err) setGlobalState('userStrategies', [{strategy: "You don't have any strategy", id: ""}])
            else setGlobalState('userStrategies', response.data)
        })
    }

    React.useEffect(() => {
            getStrategies()
        }, [user]
    )

    return (
        <div className="tab-your-strategy">
            <Title Title1="Take a look at " Title2="Your Strategies"/>
            <div className="strat-list">
                <div className="subtitles-tab-strategy text-black-16px">
                    <span style={{minWidth: 2 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>n°</span>
                    <span style={{minWidth: 15 + 'rem', fontWeight: 700}}>Name Strategy</span>
                </div>
                {userStrategies.map((strat, index) => (
                    <Strategy key={index} idNumber={index + 1} nameStrategy={strat['strategy']}
                              isFutures={strat['futures']}/>
                ))}

            </div>

        </div>
    )
}

export default YourStrategy;

