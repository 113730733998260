import React, {useEffect} from 'react'
import "./Payments.css"
import Title from "../Title/Index.jsx";
import {getPaymentById, getPaymentLink} from "../../services/api";
import {useGlobalState} from "../../App";
import ModalPayment from "../ModalPayment/ModalPayment";
import {useNavigate} from "react-router-dom";

const GridPayments = () => {

    const [payments, setPayments] = React.useState([])
    const id = useGlobalState('IdUser')[0];
    const [isPayPopUp, setIsPayPopUp] = React.useState(false)
    const [urlDebt, setUrlDebt] = React.useState('')
    const navigate = useNavigate()
    const user = useGlobalState('username')[0];
    const [amount, setAmount] = React.useState()

    const handlePay = (toPay) => {
        setAmount(toPay)
        getPaymentLink('Monthly fees', toPay, id).then((response) => {
            setUrlDebt(response.data.url)
            setIsPayPopUp(true)
        })
    }

    const showPay = () => {
        window.open(urlDebt, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
        navigate('/dashboard_investor/user=' + user, {replace: true})
    }

    useEffect(() => {
        getPaymentById(id).then((response) => {
            if (response.data) {
                console.log(response.data)
                setPayments([...response.data])
            }
        })
    }, []);


    return (
        <div className='pay-tab'>
            <Title Title1="Check your " Title2="Payments"/>

            <div className="pay-tab-header">
                <span className="text-black-16px" style={{fontWeight: 600}}>Date</span>
                <span className="text-black-16px" style={{fontWeight: 600}}>Amount</span>
                <span className="text-black-16px" style={{fontWeight: 600}}>Status</span>
            </div>
            <div className="pay-tab-container">
                { payments.length > 0 ? payments.map(pay => {
                    const date = new Date(pay.date)
                    return <div className="pay-tab-body">
                        <div className="pay-tab-row">
                            <span className="text-black-16px">{date.toLocaleDateString()}</span>
                            <span className="text-black-16px">{pay.fee +' €' }</span>
                            <span className="text-black-16px">{pay.payed ? 'PAID' : 'PENDING'}</span>
                        </div>
                        <button className="btn-connect" style={{fontWeight: 600, display:pay.payed  && 'none'}} onClick={() => handlePay(pay.fee)}>PAY
                        </button>
                    </div>
                }) : <span className="text-black-16px" style={{opacity: .8,padding:'1em 2em'}}>You don't have any payments</span>}
            </div>
            {isPayPopUp && <ModalPayment setPop={setIsPayPopUp} blacklist={false}
                                         payCard={showPay} amount={amount}/>}
        </div>
    )
}

export default GridPayments