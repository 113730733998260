import React, {useState} from 'react';
import "./Dropdown.css";
import Item from "./Item";

const Dropdown = (props) => {

    const [isActive, setIsActive] = React.useState(false);
    const [contentData, setContentData] = React.useState(props.contents);
    const [subActive, setSubActive] = React.useState(false);

    React.useEffect(() => {
        setContentData(props.contents)
    }, [props.contents]);

    return (
        <div className="dropdown" style={{minWidth:props.width && props.width + 'em', width:props.width && props.width + 'em'}}>
            <div className="dropdown-btn text-black-16px"
                 style={{padding: props.padding + 'em 1.25em', opacity: .8}}
                 onClick={(e) => setIsActive(!isActive)}>
                {props.selected}
                <img className="icon-dropdown" alt="" src={"./img/down-arrow.png"} style={{transform: isActive && "rotate(-180deg)"}}></img>
            </div>
            {isActive && (
                <div className="dropdown-content text-black-16px"
                     style={{minWidth:props.width && props.width + 'em', width:props.width && props.width + 'em'}}
                      onMouseLeave={e => setIsActive(!isActive)}>
                    {subActive &&
                        <div className="back-button-dropdown" onClick={e => {
                            setSubActive(false);
                            setContentData(props.contents)
                        }}><img alt="" className="icon-back-arrow" src={ "./img/back-arrow.png"}/></div>
                    }
                    {contentData.map((content) => (
                        <Item key={Math.random() * 100}
                              name={content['name']}
                              sub={content['sub']}
                              tag={content['tag']}
                              symbol={content['symbol']}
                              setSelected={props.setSelected}
                              setSymbol={props.setSymbol}
                              setContentData={setContentData}
                              setIsActive={setIsActive}
                              setSubActive={setSubActive}
                              isDisabled={props.disabled}
                        />

                    ))}
                </div>
            )}
        </div>
    );
}

export default Dropdown;