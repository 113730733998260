import React from "react"
import "./Modal.css"

const Modal = (props) => {
    return (
        <div className="background-modal">
            <div className="container-modal text-black-16px">
                <label style={{fontWeight: 700, paddingBottom: 1 + 'rem', borderBottom: 'solid ' + 1 + 'px' + ' #00000060'}}>Warning</label>
                <label style={{paddingTop: 1 + 'rem', paddingBottom: 1 + 'rem'}}>{props.content}</label>
                <div className="row-modal text-white-16px">
                    <div className="cancel-button" onClick={() => props.setPop(false)}>Cancel</div>
                    <div className="continue-button" onClick={() => {
                        props.setPop(false);
                        !props.notClose && props.setIsEditable(false);
                        !props.notClose && props.setErr("")
                        !props.notClose && props.setMsg("")
                        props.action();
                    }}>Continue</div>
                </div>
            </div>
        </div>
    )
}

export default Modal;