import * as React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Header from "../Header/Index.tsx";
import Footer from "../Footer/Index";

export default function NotFound(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box className="news text-black-16px">
        <Text className="alert-title">404 non trovato</Text>
        <Text>
          Sembra che la pagina che stai cercando non esista o non possiede le
          autorizazioni necessarie per vistare questa pagina
        </Text>
        <Box display="flex" justifyContent="center">
          <Link className="news-btn" to="/">
            Torna alla home
          </Link>
        </Box>
      </Box>
      <Box height={50} />
      <Footer />
    </Box>
  );
}
