import React from "react";
import "./YourPortfolio.css";
import Title from "../Title/Index.jsx";
import {useGlobalState} from "../../App";
import Axios from "axios";
import Portfolio from "./Portfolio.jsx";
import AddPortfolioModal from "../AddPortfolioModal/Index.jsx";
import {getNewPortfolios} from "../../services/api";

const YourPortfolio = () => {

    let user = useGlobalState('username')[0]

    const [portfolioList, setPortfolioList] = React.useState([]);

    const [isAddPop, setIsAddPop] = React.useState(false)


    const getPortfolios = () => {
        getNewPortfolios(user).then((response) => {
            if (response.data.err) {
                setPortfolioList([{portfolio: "You don't have any portfolio", id: ""}])
            }
            else {
                setPortfolioList(response.data)
            }
        })
    }

    React.useEffect(() => {
            getPortfolios()
        },[isAddPop]
    )

    return (
        <div className="tab-your-portfolios">
            <Title Title1="Take a look at " Title2="Your Portfolios"/>
            <div className="strat-list">
                <div className="subtitles-tab-strategy text-black-16px">
                    <span style={{minWidth: 2 + 'rem', fontWeight: 700, letterSpacing: 0.03 + 'em'}}>n°</span>
                    <span style={{minWidth: 15 + 'rem', fontWeight: 700}}>Name Portfolio</span>
                </div>
                {portfolioList.map((portfolio, index) => (
                    <Portfolio key={index} idNumber={portfolio['portfolio'] === "You don't have any portfolio" ? "" : index + 1}
                               namePortfolio={portfolio['portfolio']}  getPortfolio={getPortfolios}/>
                ))}

            </div>
            <div className="add-portfolio" onClick={() => setIsAddPop(true)}><label className="text-white-16px">Add new Portfolio</label></div>
            {isAddPop && <AddPortfolioModal setPop={setIsAddPop} getPortfolio={getPortfolios} /> }
        </div>);
}

export default YourPortfolio;