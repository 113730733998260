import React from "react"
import "./AddPortfolioModal.css"
import {useGlobalState} from "../../App";
import Axios from "axios";
import {getAllPortofolios, saveNewPortfolio} from "../../services/api";
import Dropdown from "../Dropdown/Index.jsx";

const AddPortfolioModal = (props) => {

    const [nameNewPortfolio, setNameNewPortfolio] = React.useState("")
    const [allPortfolioList, setAllPortfolioList] = React.useState([])
    const [msg, setMsg] = React.useState("")
    let user = useGlobalState('username')[0]
    let userStrategies = useGlobalState('userStrategies')[0]
    const [strategyList, setStrategyList] = React.useState([...userStrategies])
    const riskList = [{name: "Low"}, {name: "Mid"}, {name: "High"}]
    const [selectedRisk, setSelectedRisk] = React.useState(riskList[0].name);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [info, setInfo] = React.useState('');

    React.useEffect(() => {
        setStrategyList(
            strategyList.map(obj => {
                return {...obj, disabled: true, weight: 0.0000, leverage: 1}
            })
        )
        getAllPortofolios().then((response) => {
            if (response.data.err) console.log("Err")
            else setAllPortfolioList(response.data)
        })

    }, [])
    const handleChange = (event) => {
        setNameNewPortfolio(event.target.value)
    }

    const handleDelete = (name) => {
        setStrategyList(
            strategyList.map((strat) => {
                if (strat['strategy'] === name) return {...strat, disabled: true}
                else return strat
            })
        )
    }
    const handleAdd = (name) => {
        setStrategyList(
            strategyList.map((strat) => {
                if (strat['strategy'] === name) return {...strat, disabled: false}
                else return strat
            })
        )
    }

    const handleValue = (event, name) => {
        setStrategyList(
            strategyList.map((size) => {
                if (size['strategy'] === name) return {...size, weight: event.target.value}
                else return size
            })
        )

    }

    const handleLeverage = (event, name) => {
        setStrategyList(
            strategyList.map((size) => {
                if (size['strategy'] === name) return {...size, leverage: event.target.value}
                else return size
            })
        )

    }


    const save = () => {

        let sumWeigths = strategyList.map(data => !data['disabled'] && parseFloat(data.weight)).reduce((a, b) => a + b)
        let appo = true


        if (strategyList[0].strategy !== "You don't have any strategy") {
            allPortfolioList.map(obj => {
                if (obj['portfolio'] === nameNewPortfolio) {
                    appo = false
                    setMsg("Name already taken")
                }
            })

            if (nameNewPortfolio === null || nameNewPortfolio.match(/^ *$/) !== null) {
                appo = false
                setMsg("The name can't be empty or all whitespace")
            }

            if (sumWeigths === 1) {
                if (appo) {
                    saveNewPortfolio(strategyList, nameNewPortfolio.replaceAll(' ', '_'), user, selectedRisk, info).then(response => {
                        if (response.data.save) {
                            setMsg("")
                            props.setPop(false)
                        }
                    })

                }
                } else {
                    setMsg("The sum of the sizing must be equal to 1")
                }
            } else {
                setMsg("")
                props.setPop(false)
            }
        }


        const show = strategyList[0].strategy !== "You don't have any strategy"


        return (
            <div className="background-add-modal">
                <div className="container-add-modal">
                    <label className="text-black-16px" style={{fontWeight: 700}}>Add Portfolio</label>
                    <div className="input-add-portfolio">
                        <label className="text-black-16px">Portfolio Name:</label>
                        <input
                            className="input-data-portfolio text-black-16px"
                            style={{minWidth: 9 + 'rem'}}
                            type="text"
                            id="namePortfolio"
                            name="namePortfolio"
                            onChange={(event) => {
                                handleChange(event)
                            }}
                        />
                    </div>
                    <div style={{display:'flex', columnGap:'.5em',alignItems:'center'}}>
                        <label className="text-black-16px">Risk Profile:</label>
                        <Dropdown contents={riskList} selected={selectedRisk}
                                  setSelected={setSelectedRisk}
                                  padding={0.7} width={7} disabled={false}/>
                    </div>
                    <button className="text-white-16px info-area-button"  onClick={() => setOpenInfo(!openInfo)}
                            style={{alignSelf: 'start',fontSize:'14px'}}>{openInfo ? 'CLOSE INFO' : 'EDIT INFO'}</button>
                    {openInfo &&
                        <div className="info-area">
                            <textarea className="info-text-edit" placeholder={'Insert a description'} value={info} onChange={(e) => setInfo(e.target.value)}/>
                        </div>
                    }
                    <div className="strategies-modal-portfolio">
                        {strategyList.map((strat, index) => (
                            <div key={index} className="strategy-list"
                                 style={strat['disabled'] ? (show ? {opacity: 0.3} : {opacity: 1}) : {opacity: 1}}>
                                <div className="input-modal">
                                    {show &&
                                        <input
                                            type="checkbox"
                                            id={"addStrat" + index}
                                            onChange={() => {
                                                strat['disabled'] ? handleAdd(strat['strategy']) : handleDelete(strat['strategy'])
                                            }}
                                            defaultChecked={false}
                                        />}
                                    <label htmlFor={"addStrat" + index}
                                           className="text-black-16px"
                                           style={{
                                               overflowWrap: 'anywhere',
                                               maxWidth: 20 + 'rem'
                                           }}>{strat['strategy']}</label>
                                </div>
                                {show &&
                                    <div style={{display: 'flex', columnGap: 0.5 + 'rem'}}>
                                        <div className="input-portfolio">
                                            <label className="text-black-16px">Leverage:</label>
                                            <input
                                                className="input-data-portfolio text-black-16px"
                                                type="number"
                                                id="leverage"
                                                name="leverage"
                                                defaultValue={strat['leverage']}
                                                disabled={strat['disabled']}
                                                onChange={(event) => {
                                                    handleLeverage(event, strat['strategy'])
                                                }}
                                            />
                                        </div>
                                        <div className="input-portfolio">
                                            <label className="text-black-16px">Sizing:</label>
                                            <input
                                                className="input-data-portfolio text-black-16px"
                                                type="number"
                                                id="sizing"
                                                name="sizing"
                                                defaultValue={strat['weight']}
                                                disabled={strat['disabled']}
                                                onChange={(event) => {
                                                    handleValue(event, strat['strategy'])
                                                }}
                                            />
                                        </div>
                                    </div>}
                            </div>
                        ))}
                    </div>
                    <small className="text-danger">{msg}</small>
                    <div className="row-modal text-white-16px">
                        <div className="cancel-button" onClick={() => {
                            props.setPop(false)
                            setMsg("")
                        }}>Cancel
                        </div>
                        <div className="continue-button" onClick={() => {
                            save()
                        }}>Continue
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    export default AddPortfolioModal
