import React from "react"
import "./StatsInvestor.css"
import Title from "../Title/Index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Axios from "axios";
import {useGlobalState} from "../../App";
import {getDataGlobalPnl} from "../../services/api";


const StatsInvestor = () => {

    Number.prototype.round = function (p) {
        p = p || 10;
        return parseFloat(this.toFixed(p));
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Tooltip,
        Legend
    );

    ChartJS.defaults.scale.grid.drawOnChartArea = false;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }

        },
        scaleShowLabels: false
    };

    let user = useGlobalState('username')[0]
    const id = useGlobalState('IdUser')[0];
    const token = useGlobalState('token')[0]
    const [allGlobalPnl, setAllGlobalPnl] = React.useState([])
    const [labels, setLabels] = React.useState([]);
    const [dataChart, setDataChart] = React.useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = React.useState('14g');
    const [pnl, setPnl] = React.useState(0);

    const timeframes = {
        '14g': {
            dataPoints: 7,
            period: 'DAILY',
            subPoints: 2
        },
        '30g': {
            dataPoints: 10,
            period: 'DAILY',
            subPoints: 3
        },
        '6m': {
            dataPoints: 6,
            period: 'MONTHLY',
            subPoints: 1
        },
        '1y': {
            dataPoints: 12,
            period: 'MONTHLY',
            subPoints: 1
        },
        'all': {
            dataPoints: 10,
            period: 'YEARLY',
            subPoints: 1
        }
    }


    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: dataChart,
                borderColor: '#4e8098',
                tension: 0.2

            }
        ],
    };

    console.log(data)

    const handleData = (tf) => {
        console.log('data')
        console.log(tf)
        const pnlRef = allGlobalPnl.filter((obj) => {
            return obj.period === tf.period
        })
        console.log(pnlRef)
        let result = []
        let sum = 0
        for (let i = 0; i < tf.dataPoints; i++) {
            let appo = 0

            for (let j = 0; j < tf.subPoints; j++) {
                if (pnlRef[i * tf.subPoints + j] === undefined) break
                appo = appo + parseFloat(pnlRef[i * tf.subPoints + j].pnl)
            }
            sum = sum + appo
            result.push(sum.toFixed(1))
        }
        console.log(result)
        setDataChart(result)
    }
    console.log(selectedTimeframe)
    const handleLabels = (tf) => {
        console.log('labels')
        console.log(tf)
        const pnlRef = allGlobalPnl.filter((obj) => {
            return obj.period === tf.period
        })
        const result = []
        for (let i = 0; i < tf.dataPoints; i++) {
            if (pnlRef[i * tf.subPoints] === undefined) break
            const date = pnlRef[i * tf.subPoints].date.split('T')[0]
            result.push(date)
        }
        console.log(result)
        setLabels(result)
    }

    const handlePnl = (tf) => {
        console.log('pnl')
        console.log(tf)
        const pnlRef = allGlobalPnl.filter((obj) => {
            return obj.period === tf.period
        })
        let sum = 0
        for (let i = 0; i < tf.dataPoints; i++) {
            let appo = 0
            for (let j = 0; j < tf.subPoints; j++) {
                if (pnlRef[i * tf.subPoints + j] === undefined) break
                appo = appo + parseFloat(pnlRef[i * tf.subPoints + j].pnl)
            }
            sum = sum + appo
        }
        console.log(sum.round(1))
        setPnl(sum.round(1))
    }

    const onChange = (obj, key) => {
        console.log(obj, key)
        if (key === selectedTimeframe) return
        setSelectedTimeframe(key)
        handleData(obj)
        handleLabels(obj)
        handlePnl(obj)
    }

    React.useEffect(() => {
        getDataGlobalPnl(id).then(async (response) => {
            if (response.data.length > 0) {
                setAllGlobalPnl(response.data)

                console.log(response.data)
            } else console.log("No Data Found")
        })

    }, [])

    React.useEffect(() => {
        if (allGlobalPnl.length > 0) {
            handleData(timeframes['14g'])
            handleLabels(timeframes['14g'])
            handlePnl(timeframes['14g'])
        }
    }, [allGlobalPnl])

    return (
        <div className="tab-stats">
            <Title Title1="Take a look at" Title2=" your statistics"/>
            <div className="row-stats">
                <p className='text-black-16px'>This feature will coming soon</p>
                {/*<div className="chart-pnl">
                    <Line data={data} options={options}/>
                </div>
                    <div className="global-pnl">
                    <span className=" subtitle text-black-21px"
                    style={{opacity: 0.9, color: "#4e8098"}}>Global P&L
            </span>
            <div className="timeframe-stats text-black-16px">
                {Object.keys(timeframes).map((key, index) => {
                    return <span key={index} onClick={() => onChange(timeframes[key], key)}
                                 style={{color: selectedTimeframe === key && "#4e8098"}}>{key}</span>
                })
                }
            </div>
            <span className="pnl text-black-32px"
                  style={{color: pnl > 0 ? "#73CE62FF" : "#ff5858"}}>{(pnl > 0 ? "+" : "") + pnl.toFixed(2)}</span>
        </div>
*/}

            </div>
        </div>
    )
}

export default StatsInvestor