import React from "react"
import "./PlatformInvestor.css"
import {setGlobalState, useGlobalState} from "../../App";
import {useNavigate} from "react-router-dom";
import HeaderPlatform from "../HeaderPlatform/Index.jsx";
import StatsInvestor from "../StatsInvestor/Index.jsx";
import OpenPosition from "../OpenPosition/Index.jsx";
import Marketplace from "../Marketplace/Index.jsx";
import Axios from "axios";
import ActivePortfolio from "../ActivePortfolio/Index.jsx";
import {getActivePortfolio} from "../../services/api";


const PlatformInvestor = () => {

    const login = useGlobalState('loggedIn')[0];
    const isTrader = useGlobalState('isTrader')[0];
    const user = useGlobalState('username')[0];
    const id = useGlobalState('IdUser')[0];
    const token = useGlobalState('token')[0];
    const [activePortfolio, setActivePortfolio] = React.useState([])
    const navigate = useNavigate()
    const [connectedPort, setConnectedPort] = React.useState(Object.keys(localStorage)
        .filter(key => key.startsWith("connected_"))
        .map(key => {
            return {
                ex: key.split("_")[1],
                value: localStorage.getItem(key)
            }
        }));

    const [disconnectedPort, setDisconnectedPort] = React.useState(Object.keys(localStorage)
        .filter(key => key.startsWith("disconnected_"))
        .map(key => {
            return {
                ex: key.split("_")[1],
                value: localStorage.getItem(key)
            }
        }));

    const [editedPort, setEditedPort] = React.useState(Object.keys(localStorage)
        .filter(key => key.startsWith("edited_"))
        .map(key => {
            return {
                ex: key.split("_")[1],
                port: key.split("_").slice(2).join("_"),
                value: localStorage.getItem(key)
            }
        }))
    console.log(editedPort)
    console.log(Object.keys(localStorage))
    const handleLocalStorageUpdate = () => {
        setConnectedPort(Object.keys(localStorage)
            .filter(key => key.startsWith("connected_"))
            .map(key => {
                return {
                    ex: key.split("_")[1],
                    value: localStorage.getItem(key)
                }
            }))
        setDisconnectedPort(Object.keys(localStorage)
            .filter(key => key.startsWith("disconnected_"))
            .map(key => {
                return {
                    ex: key.split("_")[1],
                    value: localStorage.getItem(key)
                }
            }))
        setEditedPort(Object.keys(localStorage)
            .filter(key => key.startsWith("edited_"))
            .map(key => {
                return {
                    ex: key.split("_")[1],
                    port: key.split("_").slice(2).join("_"),
                    value: localStorage.getItem(key)
                }
            }))
    }

    const fetchActivePortfolio = async () => {
        await getActivePortfolio(id).then((response) => {
            console.log('response', response.data)
            if (response.data && JSON.stringify(response.data) !== JSON.stringify(activePortfolio)) {
                setActivePortfolio([...response.data])
            }
                if (disconnectedPort.length > 0)
                    setDisconnectedPort(disconnectedPort.filter(key => {
                        console.log(key.ex, key.value)
                        console.log(![...response.data].some(elem => elem.exchange === key.ex && elem.portfolio === key.value))
                        if (![...response.data].some(elem => elem.exchange === key.ex && elem.portfolio === key.value)) {
                            console.log('cancella')
                            localStorage.removeItem('disconnected_' + key.ex + '_' + key.value);
                            return false
                        }
                        return true
                    }))
                if (connectedPort.length > 0)
                    setConnectedPort(connectedPort.filter(key => {
                        if ([...response.data].some(elem => elem.exchange === key.ex && elem.portfolio === key.value)) {
                            localStorage.removeItem('connected_' + key.ex + '_' + key.value);
                            return false
                        }
                        return true
                    }))
                if (editedPort.length > 0) {
                    setEditedPort(editedPort.filter(key => {
                        if ([...response.data].some(elem => elem.exchange === key.ex && elem.portfolio === key.port && parseFloat(elem.portfolio_cut) === parseFloat(key.value))) {
                            localStorage.removeItem('edited_' + key.ex + '_' + key.port);
                            return false
                        }
                        return true
                    }))
                }


        })
    }

    React.useEffect(() => {
        fetchActivePortfolio()
    }, [])

    return (
        <div className="background">
            <HeaderPlatform/>
            <div className="col-platform">
                <StatsInvestor/>
                <Marketplace activePortfolio={activePortfolio} connectedPort={connectedPort}
                             editedPort={editedPort}
                             onLocalStorageUpdate={handleLocalStorageUpdate}/>
                {activePortfolio.length > 0 &&
                    <ActivePortfolio activePortfolio={activePortfolio} disconnectedPort={disconnectedPort}
                                     editedPort={editedPort}
                                     onLocalStorageUpdate={handleLocalStorageUpdate}/>}
                <OpenPosition/>
            </div>
        </div>
    )
}

export default PlatformInvestor