import React from "react";
import "./YourStrategy.css";
import CustomizeButton from "../CustomizeButton/Index.jsx";
import Axios from "axios";
import {useGlobalState} from "../../App";
import {saveFutures} from "../../services/api";


const Strategy = (props) => {

    const [isEditable, setIsEditable] = React.useState(false);
    const [isFutures, setIsFutures] = React.useState()
    let user = useGlobalState('username')[0]


    React.useEffect(() => {
            props.isFutures === 1 ? setIsFutures(true) : setIsFutures(false)
        },[]
    )

    const save = () => {

        isFutures ? setIsFutures(1) : setIsFutures(0)

       saveFutures(isFutures, user, props.nameStrategy).then(response => {
            if (response.data.save) {
                setIsEditable(false)
            }
        })
    }

    return (<>
        <div className="strategy-tab">
            <div className="group-1 text-black-16px">
                <span style={{minWidth: 2 + 'rem'}}>{props.idNumber}</span>
                <span style={{
                    minWidth: 15 + 'rem',
                    maxWidth: 15 + 'rem',
                    overflowWrap: 'break-word'
                }}>{props.nameStrategy.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}</span>
            </div>
            {props.nameStrategy !== "You don't have any strategy" && (
                <CustomizeButton is={isEditable} set={setIsEditable} submit={save} light={true}/>
            )}
        </div>
        {isEditable && (
            <div className="edit-area">
                <div className="input-check-strategy ">
                    <input
                        type="checkbox"
                        id="isFuture"
                        defaultChecked={isFutures}
                        onChange={(event) => setIsFutures(!isFutures)}
                    />
                    <label className="text-black-16px">Is Future</label>
                </div>
            </div>

        )}
    </>);
}

export default Strategy;