import React from "react";
import "./Login.css";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import bcrypt from "bcryptjs"
import {setGlobalState} from "../../App";
import {checkUserBlacklist, getApiKeyInvestor, setAuthToken} from "../../services/api";
import {login, createToken} from "../../services/auth"
import {forgotPassword} from "../../services/api";
import ModalPayment from "../ModalPayment/ModalPayment";

function Login(props) {

    const [password, setPassword] = React.useState("");
    const [user, setUser] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [showForgotPassword, setShowForgotPassword] = React.useState(false);
    const [emailForgot, setEmailForgot] = React.useState("");
    const [showSentEmail, setShowSentEmail] = React.useState(false);
    const navigate = useNavigate()
    const [isPayPopup, setIsPayPopup] = React.useState(false)
    const [urlDebt, setUrlDebt] = React.useState('')
    const [amount, setAmount] = React.useState()

    const auth = async () => {
        setIsLoading(true)
        setPasswordError('')
        if (user === '' || password === '') {
            setPasswordError('Missing Data')
            setIsLoading(false)
        } else
            await login({
                username: user.trim(),
                isTrader: props.isTrader
            }).then((response) => {
                if (response.data) {
                    bcrypt.compare(password, response.data.password, async function (err, res) {
                        if (res) {
                            await createToken({
                                username: user.trim(),
                                password: password.trim(),
                                isTrader: props.isTrader
                            }).then(async (resAUTH) => {
                                if (resAUTH.data !== 'Error') {
                                    
                                    setGlobalState('token', resAUTH.data.access_token)
                                    await setAuthToken(resAUTH.data.access_token)
                                    await getApiKeyInvestor(user.trim()).then((response) => {
                                        if (response.data.length > 0) {
                                            setGlobalState('apiKeyInvestor', [...response.data])
                                        }
                                    })
                                    setGlobalState('IdUser', response.data.id_user)
                                    setGlobalState('emailUser', response.data.email)
                                    setGlobalState('nameUser', typeof response.data.name !== 'undefined' ? response.data.name : '')
                                    setGlobalState('surnameUser', typeof response.data.surname !== 'undefined' ? response.data.surname : '')
                                    setGlobalState('username', user.trim())
                                    setGlobalState('password', response.data.password)
                                    setGlobalState('isTrader', props.isTrader)

                                    let sessionData = {
                                        token: resAUTH.data.access_token,
                                        IdUser: response.data.id_user,
                                        emailUser: response.data.email,
                                        nameUser: typeof response.data.name !== 'undefined' ? response.data.name : '',
                                        surnameUser: typeof response.data.surname !== 'undefined' ? response.data.surname : '',
                                        username: user.trim(),
                                        password: response.data.password,
                                        isTrader: props.isTrader,
                                        loggedDate: Date.now()
                                    }
                                    localStorage.setItem('sessionData', JSON.stringify(sessionData))
                                    if (props.isTrader) {
                                        setGlobalState('loggedIn', res)
                                        setIsLoading(false)
                                        navigate('/dashboard_trader/user=' + user, {replace: true})
                                    }
                                  
                                    else {
                                        // const blacklist = await checkUserBlacklist(response.data.id_user)
                                        //
                                        // if (blacklist.data.isBlacklist !== 'error') {
                                        //
                                        //     if (blacklist.data.url) {
                                        //         console.log('user is blacklisted, have to pay:' + blacklist.data.url)
                                        //         setUrlDebt(blacklist.data.url)
                                        //         setAmount(blacklist.data.amount)
                                        //         setIsPayPopup(true)
                                        //         setIsLoading(false)
                                        //     } else {
                                                setIsLoading(false)
                                                console.log('user is not blacklisted')
                                                setGlobalState('loggedIn', res)
                                                navigate('/dashboard_investor/user=' + user, {replace: true})
                                            // }
                                        // } else {
                                        //     setIsLoading(false)
                                        //     setPasswordError('Something went wrong, please try again')
                                        // }
                                    }

                                    /*props.isTrader ? navigate('/dashboard_trader/user=' + user, {replace: true})
                                        : navigate('/dashboard_investor/user=' + user, {replace: true})*/

                                } else {
                                    setIsLoading(false)
                                    setPasswordError(
                                        res.data
                                    );
                                }
                            })
                        } else {
                            setIsLoading(false)
                            setPasswordError("Password or username are not correct")
                        }
                    })
                } else {
                    setIsLoading(false)
                    setPasswordError(
                        response.data
                    );
                }
            })
    }

    const loginSubmit = async (e) => {
        e.preventDefault();
        await auth();
    };

    const forgotPasswordSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setEmailError('')
        if (emailForgot === '') {
            setIsLoading(false)
            setEmailError('Missing Data')
        } else {
            forgotPassword(emailForgot.trim(), props.isTrader).then((response) => {
                console.log(response.data)
                if (response.data.sent) {
                    setShowSentEmail(true)
                    setIsLoading(false)
                    setEmailError('')
                } else if (response.data.error) {
                    setIsLoading(false)
                    setEmailError(response.data.error)
                }
            })
        }
    }


    const showPay = () => {
        window.open(urlDebt, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
    }

    return (
        <div className="login">
            <Link to="/" style={{alignSelf: 'flex-start', padding: 1 + 'em'}}><img
                src="./img/LogoTextWhite.png" style={{width: 12 + 'rem'}}/></Link>
            <form className="col-login" onSubmit={!showForgotPassword ? loginSubmit : forgotPasswordSubmit}>
                {!showForgotPassword ? <>
                        {!props.isTrader && <div style={{alignSelf: 'flex-end'}}>
                            <span className="text-black-16px">Haven't registered yet? </span>
                            <Link to="/sign_up" className="text-cyan-16px" style={{cursor: 'pointer'}}>SIGN UP NOW</Link>
                        </div>}
                        <span className="text-black-50px">{(props.isTrader ? "Trader" : "User") + " Login"}</span>
                        <span className="text-black-16px" style={{maxWidth: 40 + 'em', opacity: 0.8}}>Start investing in cryptos taking advantage of solutions managed by professionals. Sign in and join us.</span>
                        <div className="input-group">
                            <label className="text-black-16px">Username/Company Name</label>
                            <input
                                className="input-data text-black-16px"
                                aria-describedby="emailHelp"
                                placeholder="Enter Username"
                                onChange={(event) => setUser(event.target.value.toLowerCase())}
                            />
                            <small id="emailHelp" className="text-danger ">
                                {emailError}
                            </small>
                        </div>
                        <div className="input-group">
                            <label className="text-black-16px">Password</label>
                            <input
                                type="password"
                                className="input-data text-black-16px"
                                id="PasswordInput"
                                aria-describedby="passwordError"
                                placeholder="Enter password"
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <small id="passwordError" className="text-danger ">
                                {passwordError}
                            </small>
                        </div>

                        <div className="button-group">
                            <button type="submit" className="submit-login text-white-16px" disabled={isLoading}
                                    style={{padding: isLoading && '0.5em 1.5em'}}>
                                {isLoading ? <img style={{width: '2em'}} src='./img/loading.gif'/> : 'Login'}
                            </button>
                            <span className="text-cyan-16px"
                                  onClick={() => setShowForgotPassword(true)}>Forgot Password?</span>
                        </div>
                    </> :
                    !showSentEmail ?
                        <>
                            <span className="text-black-50px">Forgot Password</span>
                            <span className="text-black-16px" style={{maxWidth: 40 + 'em', opacity: 0.8}}>Enter your email address and we will send you a link to reset your password.
                            </span>
                            <div className="input-group">
                                <label className="text-black-16px">Email:</label>
                                <input className="input-data text-black-16px"
                                       placeholder="Enter Email Address"
                                       onChange={(event) => setEmailForgot(event.target.value.toLowerCase())}/>
                                <small id="emailHelp" className="text-danger ">
                                    {emailError}
                                </small>
                            </div>
                            <div className="button-group">
                                <button type="submit" className="submit-login text-white-16px" disabled={isLoading}
                                        style={{padding: isLoading && '0.5em 1.5em'}}>
                                    {isLoading ? <img style={{width: '2em'}} src='./img/loading.gif'/> : 'Send'}
                                </button>
                                <span className="text-cyan-16px" onClick={() => setShowForgotPassword(false)}
                                      style={{cursor: 'pointer'}}>Back to Login</span>
                            </div>
                        </> :
                        <>
                            <span className="text-black-50px">Email Sent</span>
                            <span className="text-black-16px" style={{maxWidth: 40 + 'em', opacity: 0.8}}>We have sent you an email with a link to reset your password.</span>
                            <div className="button-group">
                                <span className="text-cyan-16px" onClick={() => {
                                    setShowForgotPassword(false)
                                    setShowSentEmail(false)
                                }}
                                      style={{cursor: 'pointer'}}>Back to Login</span>
                            </div>
                        </>

                }


            </form>
            {isPayPopup && <ModalPayment setPop={setIsPayPopup} blacklist={true}
                                         payCard={showPay} amount={amount}/>}
        </div>
    );
}


export default Login;

