import * as React from "react";
import { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Text,
    useToast, Flex
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { QrCode } from "react-qrcode-pretty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Subscription } from "../interfaces.ts";

const paymentMethods = [
    { network: "Bitcoin", wallet: "bitcoin:1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa" },
    { network: "Ethereum", wallet: "ethereum:0x32Be343B94f860124dC4fEe278FDCBD38C102D88", chainId: 1 },
    { network: "Polygon", wallet: "ethereum:0x32Be343B94f860124dC4fEe278FDCBD38C102D88", chainId: 137 },
    { network: "XRP", wallet: "ripple:rDsbeomae4FXwgQTJp9Rs64Qg9vDiTCdBv" }
];

const subscriptions = [
    { label: "Basic", amount: 0.01 },
    { label: "Standard", amount: 0.05 },
    { label: "Premium", amount: 0.1 }
];

const QRCodeGenerator = ({ subscription }: { subscription?: Subscription }) => {
    const [qrCodeValue, setQrCodeValue] = useState("");
    const { register, handleSubmit } = useForm();
    const [addressSelected, setAddressSelected] = useState("");
    const toast = useToast();

    const onSubmit = (data: any) => {
        const selectedMethod = paymentMethods.find(method => method.network === data.paymentMethod);

        if (!selectedMethod ||  !subscription) return;

        let qrValue = "";
        switch (data.paymentMethod) {
            case "Bitcoin":
                qrValue = `${selectedMethod.wallet}?amount=${subscription?.price}`;
                break;
            case "Ethereum":
            case "Polygon":
                qrValue = `${selectedMethod.wallet}@${selectedMethod.chainId}?value=${parseInt((subscription.price||1) * 1e18 + "", 10)}`;
                break;
            case "XRP":
                qrValue = `${selectedMethod.wallet}?amount=${subscription?.price}`;
                break;
            default:
                break;
        }

        setQrCodeValue(qrValue);
        setAddressSelected(selectedMethod.wallet);
    };

    return (
        <Box mx={"auto"} w={"600px"} p={4} boxShadow={"1px 4px 10px -1px #0352"} borderRadius={28}>
            <Text className={"p-2"} color={"red.400"} textAlign={"center"} fontWeight={"bold"}>I pagamenti effettuati in criptovalute
                possono richiedere fino a 48 ore per la verifica. Grazie per la vostra pazienza e comprensione.</Text>
            <form onSubmit={handleSubmit(onSubmit)}>

                {qrCodeValue && (
                    <Box mt={10} textAlign="center">
                        <Text mb={4}>Scan this QR Code to make a payment:</Text>
                        <Box position="relative" display="inline-block">
                            <QrCode

                                value={qrCodeValue}
                                size={200}
                                variant={{
                                    eyes: "gravity",
                                    body: "gravity"
                                }}
                                color={{
                                    eyes: "#2a4e60",
                                    body: "#1e5169"
                                }}
                                image="/img/favicon-32x32.png"

                                overlap
                            />
                        </Box>
                    </Box>
                )}
                <VStack spacing={4}>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder="Name" {...register("name", { required: true })} />
                    </FormControl>

                    <FormControl id="subscription" isRequired>
                        {/*<FormLabel>Subscription</FormLabel>*/}
                        {/*<Select value={} placeholder="Select subscription" {...register("subscription", { required: true })}>*/}
                        {/*    {subscriptions.map((sub,i) => (*/}
                        {/*        <option key={sub.label} value={i}>{sub.label}</option>*/}
                        {/*    ))}*/}
                        {/*</Select>*/}
                    </FormControl>

                    <FormControl id="paymentMethod" isRequired>
                        <FormLabel>Select network</FormLabel>
                        <Select placeholder="Select payment method" {...register("paymentMethod", { required: true })}
                                onChange={(e) => {
                                    const selectedMethod = paymentMethods.find(method => method.network === e.target.value);
                                    setAddressSelected(selectedMethod ? selectedMethod.wallet : "");
                                }}>
                            {paymentMethods.map(method => (
                                <option key={method.network} value={method.network}>{method.network}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl id="selectedAddress">
                        <FormLabel>Selected Address</FormLabel>
                        <Flex align={"center"}>
                            <Input value={addressSelected.split(":")[1]} disabled />
                            <CopyToClipboard text={addressSelected.split(":")[1]} onCopy={() => toast({
                                title: "Address Copied.",
                                description: "The address has been copied to your clipboard.",
                                status: "success",
                                duration: 2000,
                                isClosable: true
                            })}>
                                <Button mt={0}>Copy</Button>
                            </CopyToClipboard>
                        </Flex>
                    </FormControl>

                    <Button type="submit" bg={"#4e8098"} colorScheme="teal" size="md">Generate QR Code</Button>
                </VStack>
            </form>
        </Box>
    );
};

export default QRCodeGenerator;
